var FWZX = {
    //处理金额每三位加逗号
    amount(val){
      if(typeof val ==="number"){
        var str = val.toString();
        var reg = str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
        val =  str.replace(reg,"$1,");
        return val
      }
    },

    //将列表转化为树
    buildTree (arr,child,parent) {
        let temp = {}
        let tree = {}
        // 数组转 键值对
        arr.forEach(item => {
        temp[item[child]] = item
        })

        let tempKeys = Object.keys(temp)
        tempKeys.forEach(key => {
        // 获取当前项
        let item = temp[key]
        // 当前项 pId
        let _itemPId = item[parent]
        // 获取父级项
        let parentItemByPid = temp[_itemPId]
        if (parentItemByPid) {
            if (!parentItemByPid.children) {
            parentItemByPid.children = []
            }
            parentItemByPid.children.push(item)
        } else {
            tree[item[child]] = item
        }
        })
        // 对象转数组并返回
        console.log(Object.keys(tree).map(key => tree[key]))
        return Object.keys(tree).map(key => tree[key])
        
    }
}

export default {
    FWZX,
}