import {post ,get, formDataPost} from '../utils/request'

//修改密码
const doChangePwd = p => post('/user-web/user/opearUserInfo/doChangePwd', p);


//支撑单位注册发送验证码接口
const login = p => formDataPost('/auth-web/login', p);

//新增菜单
const addMenu = p => post('/user-web/user/module/insert', p);

//查询所有菜单
const queryMenu = p => post('/user-web/user/module/queryFindList', p);

//分页查询当前的下级菜单
const queryPageMenu = p => post('/user-web/user/module/queryByPage', p);

//删除菜单
const delMenu = p => post('/user-web/user/module/deleteById', p);

//修改菜单
const editMenu = p => post('/user-web/user/module/update', p);

//菜单切换状态
const doSwitchModuleStatus = p => post('/user-web/user/module/doSwitchModuleStatus', p, false);


//组织机构新增
const addOrg = p => post('/user-web/user/manageOrg/insert', p);

//查询所有组织机构
const orgList = p => post('/user-web/user/manageOrg/queryFindList', p);

//分页查询组织机构
const queryOrg = p => post('/user-web/user/manageOrg/queryByPage', p);

//修改组织机构
const editOrg = p => post('/user-web/user/manageOrg/update', p);

//删除组织机构
const delOrg = p => post('/user-web/user/manageOrg/deleteById', p);


//用户新增
const addUser = p => post('/user-web/user/opearUserInfo/doAddOpearUserInfo', p);

//用户分页查询
const queryUser = p => post('/user-web/user/opearUserInfo/queryOpearUserInfoPageListByFilter', p);

//编辑用户信息
const editUser = p => post('/user-web/user/opearUserInfo/doUpdateOpearUserInfo', p);

//删除用户
const delUser = p => post('/user-web/user/opearUserInfo/doDeleteOpearUserInfo', p);

//用户状态修改
const userSwitch = p => post('/user-web/user/opearUserInfo/doSwitchUserStatus', p);


//新增角色
const addRole = p => post('/user-web/user/role/insert', p);

//查询所有角色
const queryFindList = p => post('/user-web/user/role/queryFindList ', p);


//分页查询角色
const queryRole = p => post('/user-web/user/role/queryByPage', p);

//编辑角色
const editRole = p => post('/user-web/user/role/update', p);

//删除角色
const delRole = p => post('/user-web/user/role/deleteById', p);

//根据角色查询菜单
const getManageRoleModeleByRoleId = p => post('/user-web/user/roleModule/getManageRoleModeleByRoleId', p);

//角色授权
const doAuthorizeRole = p => post('/user-web/user/roleModule/doAuthorizeRole', p);

//查询用户有权限的菜单
const getCurrentRoleModule = p => post('/user-web/user/module/getCurrentRoleModule', p);

//查询用户角色
const queryUserRolesByUserId = p => post('/user-web/user/userRole/queryUserRolesByUserId', p);


//新增字典
const addDiction = p => post('/user-web/user/dict/doInsert', p);

//获取系统内字典
const dictionList = p => post('/user-web/user/dict/queryForList', p);

//修改字典
const editDiction = p => post('/user-web/user/dict/doUpdate', p);

//新增字典值
const addDictValue = p => post('/user-web/user/dictValue/doInsert', p);

//根据字典查询字典值
const queryDicById = p => post('/user-web/user/dictValue/queryForList', p);

//编辑字典值
const editDictValue = p => post('/user-web/user/dictValue/doUpdate', p);

//修改状态
const dicSwitch = p => post('/user-web/user/dictValue/doSwitchDictValueStatus', p , false);
//获取系统中的所有类目 list
const commodityCategoryQueryForList = p => post('commodity-web/commodity/commodityCategory/queryForList', p , false);
//获取系统中的所有类目 树的格式 不包括禁用的 
const queryAllTreeCommodityCategoryPOs = p => post('commodity-web/commodity/commodityCategory/queryAllTreeCommodityCategoryPOs', p , false);
//获取系统中的所有类目 树的格式 包括禁用的
const queryOperateAllTreeCommodityCategoryPOs = p => post('commodity-web/commodity/commodityCategory/queryOperateAllTreeCommodityCategoryPOs', p , false);

//根据父类目编号获取子类目信息 不包括禁用的
const queryChildrenByParentId = p => post('commodity-web/commodity/commodityCategory/queryChildrenByParentId', p , false);
//根据父类目编号获取子类目信息 包括禁用的
const queryOperateChildrenByParentId = p => post('commodity-web/commodity/commodityCategory/queryOperateChildrenByParentId', p , false);

// 修改类目
//const doUpdate = p => post('commodity-web/commodity/commodCategory/doUpdate', p , false);
const doUpdate = p => post('commodity-web/commodity/commodityCategory/doUpdate', p , false);

// 新增类目
const doInsert = p => post('commodity-web/commodity/commodityCategory/doInsert', p , false);

// 删除类目
const doLogicDeleteById = p => post('commodity-web/commodity/commodityCategory/doLogicDeleteById', p , false);

// 改变状态接口类目
const doSwitchCommodityCategoryStatus = p => post('commodity-web/commodity/commodityCategory/doSwitchCommodityCategoryStatus', p , false);

//新增N条类目属性信息，自动删除原类目属性集合
const doBatchInsertCommodityCategoryAttrGroup = p => post('commodity-web/commodity/commodityCategory/doBatchInsertCommodityCategoryAttrGroup', p , false);

//  类目根据主键获取属性列表
const queryForCommodityCategoryAttrGroupListByCategoryId = p => post('commodity-web/commodity/commodityCategoryAttrGroup/queryForCommodityCategoryAttrGroupListByCategoryId', p , false);

//  根据类目主键Id获取所有规格列表
const queryCommodityCategorySpecListByCategoryId = p => post('commodity-web/commodity/commodityCategorySpec/queryCommodityCategorySpecListByCategoryId', p , false);

//  新增N条规格信息
const doBatchInsertCommodityCategorySpec = p => post('commodity-web/commodity/commodityCategorySpec/doBatchInsertCommodityCategorySpec', p , false);



//  查询列表
const queryForPageNoZeroList = p => post('user-web/user/shopmallUserInfo/queryForPageNoZeroList', p , false);

const queryUniqueObject = p => post('/user-web/user/shopmallUserInfo/queryUniqueObject', p , false);

const doApproveById = p => post('user-web/user/shopmallUserInfo/doApproveById', p , false);


// 根据条件分页查询品牌列表
const queryForPageBrandList = p => post('commodity-web/commodity/commodityBrand/queryForPageList', p , false);

// 新增品牌
const doInsertBrand = p => post('commodity-web/commodity/commodityBrand/doInsert', p , false);
// 修改品牌
const doUpdateBrand = p => post('commodity-web/commodity/commodityBrand/doUpdate', p , false);
// 删除品牌
const doLogicDeleteByIdBrand = p => post('commodity-web/commodity/commodityBrand/doLogicDeleteById', p , false);

// 查询品牌列表接口：
const queryCommodityBrandList = p => post('commodity-web/commodity/commodityBrand/queryCommodityBrandList', p , false);

// 获取已授权列表
const queryCommodityBrandIdListByShopId = p => post('commodity-web/commodity/commodityBrand/queryCommodityBrandIdListByShopId', p , false);

// 品牌去授权
const authorizeDoInsert = p => post('/user-web/user/shopBrandRel/doInsert', p , false);
// 品牌取消授权
const doBatchLogicDelete = p => post('/user-web/user/shopBrandRel/doBatchLogicDelete', p , false);

// 日期修改
const doUpdateAuthDateByShopIdAndbrandId = p => post('/user-web/user/shopBrandRel/doUpdateAuthDateByShopIdAndbrandId', p , false);


// 商品列表查询
const commoditySpuQueryOperateForPageList = p => post('commodity-web/commodity/commoditySpu/queryOperateForPageList', p , false);
// 商品列表修改商品
const commoditySpuDoUpdate = p => post('commodity-web/commodity/commoditySpu/doUpdate', p , false);
// 商品列表上下架接口
const doUpdateCommodityShelfTime = p => post('commodity-web/commodity/commoditySpu/doUpdateCommodityShelfTime', p , false);
// 商品列表 --查看页面 查询卖家信息
const shopmallUserInfoQueryById = p => post('user-web//user/shopmallUserInfo/queryById', p , false);
// 商品列表 --查看页面 查看商品详情
const querySpuDetailsByCommoditySpuId = p => post('commodity-web/commodity/commoditySpu/querySpuDetailsByCommoditySpuId', p , false);


//搜索订单设置
const orderSetting = p => post('/order-web/order/orderInstall/queryForList', p);

//订单设置
const orderSettingAdd = p => post('/order-web/order/orderInstall/doBatchInsert', p);

//订单列表查询
const orderSearch = p => post('/order-web/order/parentOrder/queryForOperatePageList', p);

//根据运费模板Id,查询运费
const userFreightTemplateQueryFreightTemplateById = p=>formDataPost('/user-web/user/freightTemplate/queryFreightTemplateById',p);

//数据字典
const queryUniqueObjects = p => post('/user-web/user/dict/queryUniqueObject', p)

//订单详情
const orderQueryById = p => post('/order-web/order/queryById', p)


//发货记录查询
const fahuojilu = p => post('/order-web/order/orderLogistics/queryForListByOrderId', p)

//企业认证列表
const queryForOperateApprovePageList = p => post('/user-web/user/userEnterpriseCertification/queryForOperateApprovePageList', p)


//查询企业认证详情
const companyApprovalById = p => post('/user-web/user/userEnterpriseCertification/queryUniqueObject', p)

//根据Id审批
const companyApprovalYesOrNoById = p => post('/user-web/user/userEnterpriseCertification/doUpdateApproveById', p)


//查询企业认证历史记录
const queryCompanyHistory = p => post('/user-web/user/userEnterpriseCertification/queryHistoryThisUserInfoList', p)

//查询物流详情
const queryTrack = p => post('/order-web/order/kuaiDi100/queryTrack', p, false)

//查询快递地图
const queryMapView = p => post('/order-web/order/kuaiDi100/queryMapView', p, false)

//获取摄像头token
const getAccessToken = p => post('/iot/YSY/getAccessToken',p);

// 获取物联网配置传感器分页列表
const queryForPageListIot = p => post('/datascreen-web/datascreen/iotDev/queryForPageList',p);

// 新增物联网配置传感器分页
const doInsertIot = p => post('/datascreen-web/datascreen/iotDev/doInsert',p);

// 删除物联网配置传感器分页
const doDeleteByIdIot = p => post('/datascreen-web/datascreen/iotDev/doLogicDeleteById',p);

// 编辑物联网配置传感器分页
const doUpdateIot = p => post('/datascreen-web/datascreen/iotDev/doUpdate',p);

// 获取物联网配置摄像头分页列表
const queryForPageListiotCamera = p => post('/datascreen-web/datascreen/iotCamera/queryForPageList',p);

// 新增物联网配置摄像头分页列表
const doInsertIotCamera = p => post('/datascreen-web/datascreen/iotCamera/doInsert',p);

// 修改物联网配置摄像头分页列表
const doUpdateIotCamera = p => post('/datascreen-web/datascreen/iotCamera/doUpdate',p);

// 删除物联网配置摄像头分页列表
const doDeleteByIdIotCamera = p => post('/datascreen-web/datascreen/iotCamera/doLogicDeleteById',p);

// 新增物联网配置设备阈值管理
const thresholdDoInsert = p => post('/datascreen-web/deviceThreshold/doInsert',p);

// 编辑物联网配置设备阈值管理
const thresholdDoUpdate = p => post('/datascreen-web/deviceThreshold/doUpdate',p);

// 删除物联网配置设备阈值管理
const thresholdDoDeleteById = p => post('/datascreen-web/deviceThreshold/doDeleteById',p);

// 物联网配置设备阈值管理列表
const thresholdQueryForPageList = p => post('/datascreen-web/deviceThreshold/queryForPageList',p);

// 省级大屏配置-企业维护-列表查询
const sjEnterpriseQueryForPageList = p => post('/datascreen-web/sjEnterprise/queryForPageList',p);
// 省级大屏配置-企业维护-新增
const sjEnterpriseDoInsert = p => post('/datascreen-web/sjEnterprise/doInsert',p);
// 省级大屏配置-企业维护-修改
const sjEnterpriseDoUpdate = p => post('/datascreen-web/sjEnterprise/doUpdate',p);
// 省级大屏配置-企业维护-删除
const sjEnterpriseDoDeleteById = p => formDataPost('/datascreen-web/sjEnterprise/doDeleteById',p);

// 省级大屏配置-设备维护-省级企业列表查询
const sjDeviceQueryForList = p => post('/datascreen-web/sjEnterprise/queryForList',p);
// 省级大屏配置-设备维护-列表查询
const sjDeviceQueryForPageList = p => post('/datascreen-web/sjDevice/queryForPageList',p);
// 省级大屏配置-企业维护-新增
const sjDeviceDoInsert = p => post('/datascreen-web/sjDevice/doInsert',p);
// 省级大屏配置-企业维护-修改
const sjDeviceDoUpdate = p => post('/datascreen-web/sjDevice/doUpdate',p);
// 省级大屏配置-企业维护-删除
const sjDeviceDoDeleteById = p => formDataPost('/datascreen-web/sjDevice/doDeleteById',p);

// 查看摄像头
const sjDeviceQueryDeviceBydeviceAddr = p => get('/datascreen-web/datascreen/authout/sjDevice/queryDeviceBydeviceAddr',p);


export default {
    login,
    addMenu,
    queryMenu,
    queryPageMenu,
    delMenu,
    editMenu,
    addOrg,
    orgList,
    editOrg,
    delOrg,
    addUser,
    queryUser,
    queryOrg,
    editUser,
    delUser,
    addRole,
    queryRole,
    editRole,
    delRole,
    getManageRoleModeleByRoleId,
    doAuthorizeRole,
    getCurrentRoleModule,
    queryFindList,
    queryUserRolesByUserId,
    addDiction,
    dictionList,
    editDiction,
    addDictValue,
    queryDicById,
    editDictValue,
    dicSwitch,
    userSwitch,
    queryOperateAllTreeCommodityCategoryPOs,
    queryAllTreeCommodityCategoryPOs,
    queryChildrenByParentId,
    queryOperateChildrenByParentId,
    doUpdate,
    doInsert,
    doLogicDeleteById,
    doSwitchCommodityCategoryStatus,
    doBatchInsertCommodityCategoryAttrGroup,
    queryForCommodityCategoryAttrGroupListByCategoryId,
    queryForPageNoZeroList,
    queryUniqueObject,
    doApproveById,
    queryCommodityCategorySpecListByCategoryId,
    doBatchInsertCommodityCategorySpec,
    queryForPageBrandList,
    doInsertBrand,
    doUpdateBrand,
    doLogicDeleteByIdBrand,
    queryCommodityBrandList,
    queryCommodityBrandIdListByShopId,
    authorizeDoInsert,
    doBatchLogicDelete,
    doUpdateAuthDateByShopIdAndbrandId,
    doSwitchModuleStatus,
    commoditySpuQueryOperateForPageList,
    commoditySpuDoUpdate,
    doUpdateCommodityShelfTime,
    shopmallUserInfoQueryById,
    querySpuDetailsByCommoditySpuId,
    commodityCategoryQueryForList,
    doChangePwd,
    orderSetting,
    orderSettingAdd,
    orderSearch,
    userFreightTemplateQueryFreightTemplateById,
    queryUniqueObjects,
    orderQueryById,
    fahuojilu,
    queryForOperateApprovePageList,
    companyApprovalById,
    companyApprovalYesOrNoById,
    queryCompanyHistory,
    queryTrack,
    queryMapView,
    getAccessToken,
    queryForPageListIot,
    doInsertIot,
    doDeleteByIdIot,
    doUpdateIot,
    queryForPageListiotCamera,
    doInsertIotCamera,
    doDeleteByIdIotCamera,
    doUpdateIotCamera,
    thresholdDoInsert,
    thresholdDoUpdate,
    thresholdDoDeleteById,
    thresholdQueryForPageList,
    sjEnterpriseQueryForPageList,
    sjEnterpriseDoInsert,
    sjEnterpriseDoUpdate,
    sjEnterpriseDoDeleteById,
    sjDeviceQueryForList,
    sjDeviceQueryForPageList,
    sjDeviceDoInsert,
    sjDeviceDoUpdate,
    sjDeviceDoDeleteById,
    sjDeviceQueryDeviceBydeviceAddr,
}
