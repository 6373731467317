import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'vipLogin',
    component: () => import('../views/login/vipLogin.vue')
  }, 
  {
    path: '/registered',
    name: 'registered',
    component: () => import('../views/login/registered.vue')
  },
  {
    path: '/dataScreen',
    name: 'dataScreen',
    component: () => import('../views/dataScreen/dataScreen.vue')
  },
  {
    path: '/newScreen',
    name: 'newScreen',
    component: () => import('../views/newScreen/newScreen.vue')
  },
  {
    //栖然数字化散养蛋鸡园大屏
    path: '/qiranChickenEggRoom',
    name: 'qiranChickenEggRoom',
    component: () => import('../views/dataScreen/qiranChickenEggRoom.vue')
  },
  {//省级大屏配置-摄像头页面
    path: '/cameraPage',
    name: 'cameraPage',
    component: () => import('../views/cameraPage/index.vue')
  },
  
  //后台系统内的菜单都配置为index的子路由
  {
    path: '/i',
    name: 'i',
    component: () => import('../views/index/index.vue'),
    children: [
      //首页
      {
        path: '/index', 
        name: 'index',
        component: () => import('../views/index/homePage.vue')
      },
      //用户管理
      {
        path: '/userManagent',
        name: 'userManagent',
        component: () => import('../views/userManagent/userList.vue'),
      },
      //角色管理
      {
        path: '/roleList',
        name: 'roleList',
        component: () => import('../views/roleManagement/roleList.vue'),
      },


      //数据字典
      {
        path: '/dataDictionary', 
        name: 'dataDictionary',
        component: () => import('../views/dataDictionary/dataDictionary.vue')
      },
      //菜单管理
      {
        path: '/menuManagent',
        name: 'menuManagent',
        component: () => import('../views/menuManagent/menuManagent.vue')
      },
      //机构管理
      {
        path: '/orgList',
        name: 'orgList',
        component: () => import('../views/orgManagent/orgList.vue'),
      },
      //类目管理
      {
        path: '/categoryManagement',
        name: 'categoryManagement',
        component: () => import('../views/categoryManagement/categoryManagement.vue'),
      },
      //供应商审核
      {
        path: '/supplierApproval',
        name: 'supplierApproval',
        component: () => import('../views/approval/supplierApproval.vue'),
      },
      { // 供应商详情
        path: '/supplierDetails',
        name: 'supplierDetails',
        component: () => import('../views/approval/supplierDetails.vue'),
      },
      
      //测评审核
      {
        path: '/measurementApproval',
        name: 'measurementApproval',
        component: () => import('../views/approval/measurementApproval.vue'),
      },
      //测评列表
      {
        path: '/measurementList',
        name: 'measurementList',
        component: () => import('../views/measurement/measurementList.vue'),
      },
      //测评列表
      {
        path: '/measurementClassification',
        name: 'measurementClassification',
        component: () => import('../views/measurement/measurementClassification.vue'),
      },
      //测评banner设置
      {
        path: '/measurementBanner',
        name: 'measurementBanner',
        component: () => import('../views/measurement/measurementBanner.vue'),
      },
      //论坛列表
      {
        path: '/bbsList',
        name: 'bbsList',
        component: () => import('../views/BBS/bbsList.vue'),
      },

      //商品列表
      {
        path: '/commodityList',
        name: 'commodityList',
        component: () => import('../views/commodity/commodityList.vue'),
      },
      //待审核商品
      {
        path: '/commodityWait',
        name: 'commodityWait',
        component: () => import('../views/commodity/commodityWait.vue'),
      },
      //商品详情
      {
        path: '/commodityDetails',
        name: 'commodityDetails',
        component: () => import('../views/commodity/commodityDetails.vue'),
      },
      //商品品牌
      {
        path: '/commpdityBrand',
        name: 'commpdityBrand',
        component: () => import('../views/commodity/commpdityBrand.vue'),
      },
      //客户管理-供应商管理
      {
        path: '/supplierManagementList',
        name: 'supplierManagementList',
        component: () => import('../views/customerManagement/supplierManagementList.vue'),
      },
      //客户管理-供应商详情
      {
        path: '/supplierBrandDetails',
        name: 'supplierBrandDetails',
        component: () => import('../views/customerManagement/supplierBrandDetails.vue'),
      },
      {//订单列表
        path:"/orderList",
        name:"orderList",
        component: () => import('../views/order/orderList.vue')

      },
      {
        path: '/orderDetail',
        name: 'orderDetail',
        component: () => import('../views/order/orderDetail.vue')
      },
      {
        path: '/orderSetting',
        name: 'orderSetting',
        component: () => import('../views/order/orderSetting.vue')
      },
      {
        path: '/companyList',
        name: 'companyList',
        component: () => import('../views/customerManagement/companyList.vue')
      },
      {
        path: '/companyDetail',
        name: 'companyDetail',
        component: () => import('../views/customerManagement/companyDetail.vue')
      },
      {
        path: '/egg',
        name: 'egg',
        component: () => import('../views/bigScreen/egg.vue')
      },
      {
        path: '/dataScreen1_sheep',
        name: 'dataScreen1_sheep',
        component: () => import('../views/bigScreen/dataScreen1_sheep.vue')
      },
      {
        path: '/dataScreen2_sheep',
        name: 'dataScreen2_sheep',
        component: () => import('../views/bigScreen/dataScreen2_sheep.vue')
      },
      {
        path: '/dataScreen15_processing',
        name: 'dataScreen15_processing',
        component: () => import('../views/bigScreen/dataScreen15_processing.vue')
      },
      {
        path: '/dataScreen3_potato',
        name: 'dataScreen3_potato',
        component: () => import('../views/bigScreen/dataScreen3_potato.vue')
      },
      {//大地种业马铃薯
        path: '/dataScreen5_potato',
        name: 'dataScreen5_potato',
        component: () => import('../views/bigScreen/dataScreen5_potato.vue')
      },
      {//明杰马铃薯
        path: '/dataScreen6_potato_mj',
        name: 'dataScreen6_potato_mj',
        component: () => import('../views/bigScreen/dataScreen6_potato_mj.vue')
      },{//鱼河农场马铃薯
        path: '/dataScreen6_potato_yh',
        name: 'dataScreen6_potato_yh',
        component: () => import('../views/bigScreen/dataScreen4_potato_yh.vue')
      }, {//肉牛
        path: '/dataScreen8_cow',
        name: 'dataScreen8_cow',
        component: () => import('../views/bigScreen/dataScreen8_cow.vue')
      },{//小米
        path: '/dataScreen11_xm',
        name: 'dataScreen11_xm',
        component: () => import('../views/bigScreen/dataScreen11_xm.vue')
      },{//农机
        path: '/dataScreen12_gxnj',
        name: 'dataScreen12_gxnj',
        component: () => import('../views/bigScreen/dataScreen12_gxnj.vue')
      },{//冷链仓储
        path: '/dataScreen10_llcc',
        name: 'dataScreen10_llcc',
        component: () => import('../views/bigScreen/dataScreen10_llcc.vue')
      },{//大漠蔬菜
        path: '/dataScreen13_dmsc',
        name: 'dataScreen13_dmsc',
        component: () => import('../views/bigScreen/dataScreen13_dmsc.vue')
      },{//谷物杂粮
        path: '/dataScreen16_gwzl',
        name: 'dataScreen16_gwzl',
        component: () => import('../views/bigScreen/dataScreen16_gwzl.vue')
      },{//物联网传感器配置
        path: '/sensor',
        name: 'sensor',
        component: () => import('../views/iotSet/sensor.vue')
      },{//物联网传感器配置
        path: '/gps',
        name: 'gps',
        component: () => import('../views/iotSet/GPS.vue')
      },{//物联网传感器配置
        path: '/camera',
        name: 'camera',
        component: () => import('../views/iotSet/camera.vue')
      },{//物联网阈值规则配置
        path: '/threshold',
        name: 'threshold',
        component: () => import('../views/iotSet/threshold.vue')
      },{//省级大屏配置-企业维护
        path: '/enterprise',
        name: 'enterprise',
        component: () => import('../views/provincialScreen/enterprise.vue')
      },{//省级大屏配置-设备维护
        path: '/equipment',
        name: 'equipment',
        component: () => import('../views/provincialScreen/equipment.vue')
      }
    ]
  },
  {//古塔镇数字化小米种植基地 小屏
    path: '/dataScreen11_SC',
    name: 'dataScreen11_SC',
    component: () => import('../views/smallScreen/dataScreen11_SC.vue')
  }
]

const router = new VueRouter({
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
