
// ------加减乘除
export function highPrecisionAdd(data1, data2) { // 加法  data1 + data2
  let r1, r2
  // 获取每个参数的小数的位数
  try {
    r1 = data1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = data2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  // 计算底数为10以最大小数位数为次幂的值
  const m = Math.pow(10, Math.max(r1, r2))
  // 把所有参数转为整数后相加再除以次幂的值
  return (data1 * m + data2 * m) / m
}

export function highPrecisionReduce(data1, data2) { // 减法  data1 - data2
  let r1, r2
  // 获取每个参数的小数的位数
  try {
    r1 = data1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = data2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  // 计算底数为10以最大小数位数为次幂的值
  const m = Math.pow(10, Math.max(r1, r2))
  // 精度长度以最大小数位数为长度
  const n = (r1 >= r2) ? r1 : r2
  return ((data1 * m - data2 * m) / m).toFixed(n)
}
export function highPrecisionMul(data1, data2) { // 乘法  data1 * data2
  if (data1 !== null && data1 === undefined) {
    return 0
  }
  let m = 0
  const s1 = data1.toString()
  const s2 = data2.toString()
  // 获取所有参数小数位长度之和
  try {
    if(s1.split('.')[1] != null){
      m += s1.split('.')[1].length
    }
  } catch (e) {m = 0}
  try {
    if(s2.split('.')[1] != null){
      m += s2.split('.')[1].length
    }
  } catch (e) {m =0 }
  // 替换掉小数点转为数字相乘再除以10的次幂值
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
}
export function highPrecisionDiv(data1, data2) { // 除法  data1 / data2
  if (data1 !== null && data1 === undefined) {
    return 0
  }
  let t1 = 0
  let t2 = 0
  // 获取每个参数的小数的位数
  try {
    t1 = data1.toString().split('.')[1].length
  } catch (e) {
    t1 = 0
  }
  try {
    t2 = data2.toString().split('.')[1].length
  } catch (e) {
    t2 = 0
  }
  // 把所有参数的小数点去掉转为整数
  const r1 = Number(data1.toString().replace('.', ''))
  const r2 = Number(data2.toString().replace('.', ''))
  return (r1 / r2) * Math.pow(10, t2 - t1)
}
