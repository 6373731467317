import {post ,get, formDataPost} from '../utils/request'
//大屏设置接口  鸡查询
const loadDataBO = p => post('/datascreen-web/datascreen9/loadDataBO',p);

//大屏设置接口  鸡 基础信息保存
const doSaveInfo = p => post('/datascreen-web/datascreen9/doSave',p);

//蛋鸡产蛋统计数据-新增
const doInsertEgg = p => post('/datascreen-web/datascreen9EggLaying/doInsert',p);

//蛋鸡产蛋统计数据-分页查询接口
const queryForPageListEgg = p => post('/datascreen-web/datascreen9EggLaying/queryForPageList',p);

//蛋鸡产蛋统计数据-删除
const doDeleteByIdEgg = p => post('/datascreen-web/datascreen9EggLaying/doDeleteById',p);

//蛋鸡脚环统计
const doInsertFooter = p => post('/datascreen-web/datascreen9FootRing/doInsert',p);

//蛋鸡脚环统计-查询
const queryForPageListFooter = p => post('/datascreen-web/datascreen9FootRing/queryForPageList',p);

//脚环删除
const doDeleteByIdFooter = p => post('/datascreen-web/datascreen9FootRing/doDeleteById',p);

//疾病预警 新增 
const doInsertDisease = p => post('/datascreen-web/datascreen9HealthyMonitor/doInsert',p);
//疾病预警 查询
const queryForPageListDisease = p => post('/datascreen-web/datascreen9HealthyMonitor/queryForPageList',p);
//疾病预警 查询
const doDeleteByIdDisease = p => post('/datascreen-web/datascreen9HealthyMonitor/doDeleteById',p);

//羊大屏1基础信息
const loadDataBOSheepBasicInformation1 = p => post('/datascreen-web/datascreen1/loadDataBO',p);

//羊大屏1基础信息保存

const doSaveSheepBasicInformation1 = p => post('/datascreen-web/datascreen1/doSave',p);

//羊大屏乡镇列表数据新增
const doInsertTownship1 = p => post('/datascreen-web/datascreen1IndustrialTownship/doInsert',p);
//羊大屏乡镇列表数据删除
const doDeleteByIdTownship1 = p => post('/datascreen-web/datascreen1IndustrialTownship/doDeleteById',p);
//羊大屏乡镇列表数据分页查询
const queryForPageListTownship1 = p => post('/datascreen-web/datascreen1IndustrialTownship/queryForPageList',p);

//羊大屏重点企业维护列表数据分页查询
const queryForPageListEnterprise1 = p => post('/datascreen-web/datascreen1Base/queryForPageList',p);
//羊大屏重点企业维护列表新增
const doInsertAdd = p => post('/datascreen-web/datascreen1Base/doInsert',p);
//羊大屏重点企业维护列表修改
const doInsertEdit = p => post('/datascreen-web/datascreen1Base/doUpdate',p);
//羊大屏重点企业维护列表修改
const doDeleteByIdDelete1 = p => post('/datascreen-web/datascreen1Base/doDeleteById',p);


//羊大屏乡镇列价格指数新增
const datascreen1SheepPriceDoInsert = p => post('/datascreen-web/datascreen1SheepPrice/doInsert',p);
//羊大屏羊肉乡镇店铺列表修改
const datascreen1SheepPriceDoUpdate = p => post('/datascreen-web/datascreen1SheepPrice/doUpdate',p);
//羊大屏乡镇列价格指数删除
const datascreen1SheepPriceDoDeleteById = p => post('/datascreen-web/datascreen1SheepPrice/doDeleteById',p);
//羊大屏乡镇列价格指数查询
const datascreen1SheepPriceQueryForPageList = p => post('/datascreen-web/datascreen1SheepPrice/queryForPageList',p);
//羊大屏乡镇列价格指数修改
const datascreen1SheepPriceQueryForDoUpdate = p => post('/datascreen-web/datascreen1IndustrialTownship/doUpdate',p);

//羊大屏-用户数据新增
const datascreen1WhirlyQueryForPageList = p => post('/datascreen-web/datascreen1Whirly/queryForPageList',p);

//羊大屏-用户数据新增
const datascreen1WhirlyQueryForDoInsert = p => post('/datascreen-web/datascreen1Whirly/doInsert',p);

//羊大屏-用户数据修改
const datascreen1WhirlyQueryForDoUpdate = p => post('/datascreen-web/datascreen1Whirly/doUpdate',p);

//羊大屏-用户数据删除
const datascreen1WhirlyQueryForDoDeleteById = p => post('/datascreen-web/datascreen1Whirly/doDeleteById',p);





//羊大屏---榆阳区羊肉价格指数图---售卖数据维护：查询
const datascreen1SellmapInfoQueryForPageList = p => post('/datascreen-web/datascreen1SellmapInfo/queryForPageList',p);
//羊大屏---榆阳区羊肉价格指数图---售卖数据维护：新增
const datascreen1SellmapInfoDoInsert = p => post('/datascreen-web/datascreen1SellmapInfo/doInsert',p);
//羊大屏---榆阳区羊肉价格指数图---售卖数据维护：删除
const datascreen1SellmapInfoDoDeleteById = p => post('/datascreen-web/datascreen1SellmapInfo/doDeleteById',p);

//羊大屏---榆阳区羊肉价格指数图-绿点：查询
const datascreen1ExponentialGraphQueryForPageList = p => post('/datascreen-web/datascreen1ExponentialGraph/queryForPageList',p);
//羊大屏---榆阳区羊肉价格指数图-绿点：新增
const datascreen1ExponentialGraphDoInsert = p => post('/datascreen-web/datascreen1ExponentialGraph/doInsert',p);
//羊大屏---榆阳区羊肉价格指数图-绿点：修改
const datascreen1ExponentialGraphDoUpdate = p => post('/datascreen-web/datascreen1ExponentialGraph/doUpdate',p);
//羊大屏---榆阳区羊肉价格指数图-绿点：删除
const datascreen1ExponentialGraphDoDeleteById = p => formDataPost('/datascreen-web/datascreen1ExponentialGraph/doDeleteById',p);

//羊大屏---羊肉平均价格 新增
const datascreen1AvgPriceDoInsert = p => post('/datascreen-web/datascreen1AvgPrice/doInsert',p);
//羊大屏---羊肉平均价格 修改
const datascreen1AvgPriceDoUpdate = p => post('/datascreen-web/datascreen1AvgPrice/doUpdate',p);
//羊大屏---羊肉平均价格 删除
const datascreen1AvgPriceDoDeleteById = p => formDataPost('/datascreen-web/datascreen1AvgPrice/doDeleteById',p);
//羊大屏---羊肉平均价格 查询
const datascreen1AvgPriceQueryForPageList = p => post('/datascreen-web/datascreen1AvgPrice/queryForPageList',p);


const datascreen1SellmapInfoDoUpdate = p => post('/datascreen-web/datascreen1SellmapInfo/doUpdate',p);

//羊2大屏---大屏主数据：编辑
const datascreen2DoSave = p => post('/datascreen-web/datascreen2/doSave',p);

//羊2大屏---大屏主数据：查询
const datascreen2LoadDataBO = p => post('/datascreen-web/datascreen2/loadDataBO',p);

//羊2大屏---羊子饲养品种数据：新增接口
const datascreen2SpeciesDoInsert = p => post('/datascreen-web/datascreen2Species/doInsert',p);
//羊2大屏---羊子饲养品种数据：修改接口
const datascreen2SpeciesDoUpdate = p => post('/datascreen-web/datascreen2Species/doUpdate',p);
//羊2大屏---羊子饲养品种数据：删除接口
const datascreen2SpeciesDoDeleteById= p => post('/datascreen-web/datascreen2Species/doDeleteById',p);
//羊2大屏---羊子饲养品种数据：分页查询接口
const datascreen2SpeciesQueryForPageList= p => post('/datascreen-web/datascreen2Species/queryForPageList',p);


//羊2大屏---人员比例数据：新增接口
const datascreen2JobDoInsert = p => post('/datascreen-web/datascreen2Job/doInsert',p);
//羊2大屏---人员比例数据：修改接口：
const datascreen2JobDoUpdate = p => post('/datascreen-web/datascreen2Job/doUpdate',p);
//羊2大屏---人员比例数据：删除接口：
const datascreen2JobDoDeleteById = p => post('/datascreen-web/datascreen2Job/doDeleteById',p);
//羊2大屏---人员比例数据：分页查询接口：
const datascreen2JobQueryForPageList = p => post('/datascreen-web/datascreen2Job/queryForPageList',p);

//羊2大屏---销售记录数据：新增接口
const datascreen2SellDoInsert = p => post('/datascreen-web/datascreen2Sell/doInsert',p);
//羊2大屏---销售记录数据：修改接口
const datascreen2SellDoUpdate = p => post('/datascreen-web/datascreen2Sell/doUpdate',p);
//羊2大屏---销售记录数据：删除接口
const datascreen2SellDoDeleteById = p => post('/datascreen-web/datascreen2Sell/doDeleteById',p);
//羊2大屏---销售记录数据：分页查询接口
const datascreen2SellQueryForPageList = p => post('/datascreen-web/datascreen2Sell/queryForPageList',p);

//羊2大屏---主要饲草消耗量数据：新增接口
const datascreen2FeedDoInsert = p => post('/datascreen-web/datascreen2Feed/doInsert',p);
//羊2大屏---主要饲草消耗量数据：修改接口
const datascreen2FeedDoUpdate = p => post('/datascreen-web/datascreen2Feed/doUpdate',p);
//羊2大屏---主要饲草消耗量数据：删除接口
const datascreen2FeedDoDeleteById = p => post('/datascreen-web/datascreen2Feed/doDeleteById',p);
//羊2大屏---主要饲草消耗量数据：查询接口
const datascreen2FeedQueryForPageList = p => post('/datascreen-web/datascreen2Feed/queryForPageList',p);


//羊2大屏---智能预警数据：新增接口
const datascreen2WarnDoInsert = p => post('/datascreen-web/datascreen2Warn/doInsert',p);
//羊2大屏---智能预警数据：修改接口
const datascreen2WarnDoUpdate = p => post('/datascreen-web/datascreen2Warn/doUpdate',p);
//羊2大屏---智能预警数据：删除接口
const datascreen2WarnDoDeleteById = p => post('/datascreen-web/datascreen2Warn/doDeleteById',p);
//羊2大屏---智能预警数据：查询接口
const datascreen2WarnQueryForPageList = p => post('/datascreen-web/datascreen2Warn/queryForPageList',p);

//羊2大屏---基础母羊存栏量数据：新增接口
const datascreen2BaseFemaleStatisticsDoInsert = p => post('/datascreen-web/datascreen2BaseFemaleStatistics/doInsert',p);
//羊2大屏---基础母羊存栏量数据：修改接口
const datascreen2BaseFemaleStatisticsDoUpdate = p => post('/datascreen-web/datascreen2BaseFemaleStatistics/doUpdate',p);
//羊2大屏---基础母羊存栏量数据：删除接口
const datascreen2BaseFemaleStatisticsDoDeleteById = p => post('/datascreen-web/datascreen2BaseFemaleStatistics/doDeleteById',p);
//羊2大屏---基础母羊存栏量数据：查询接口
const datascreen2BaseFemaleStatisticsQueryForPageList = p => post('/datascreen-web/datascreen2BaseFemaleStatistics/queryForPageList',p);

// 羊2大屏---育肥羊预估出栏量数据：新增接口
const datascreen2FatteningSheepStatisticsDoInsert = p => post('/datascreen-web/datascreen2FatteningSheepStatistics/doInsert',p);
// 羊2大屏---育肥羊预估出栏量数据：修改接口：
const datascreen2FatteningSheepStatisticsDoUpdate = p => post('/datascreen-web/datascreen2FatteningSheepStatistics/doUpdate',p);
// 羊2大屏---育肥羊预估出栏量数据：删除接口：
const datascreen2FatteningSheepStatisticsDoDeleteById = p => post('/datascreen-web/datascreen2FatteningSheepStatistics/doDeleteById',p);
// 羊2大屏---育肥羊预估出栏量数据：查询接口：
const datascreen2FatteningSheepStatisticsQueryForPageList = p => post('/datascreen-web/datascreen2FatteningSheepStatistics/queryForPageList',p);

// 羊2大屏---新生羔羊月度统计数据：新增接口
const datascreen2NewbornLambStatisticsDoInsert = p => post('/datascreen-web/datascreen2NewbornLambStatistics/doInsert',p);
// 羊2大屏---新生羔羊月度统计数据：修改接口
const datascreen2NewbornLambStatisticsDoUpdate = p => post('/datascreen-web/datascreen2NewbornLambStatistics/doUpdate',p);

// 羊2大屏---新生羔羊月度统计数据：删除接口
const datascreen2NewbornLambStatisticsDoDeleteById = p => post('/datascreen-web/datascreen2NewbornLambStatistics/doDeleteById',p);
// 羊2大屏---新生羔羊月度统计数据：分页查询接口：
const datascreen2NewbornLambStatisticsQueryForPageList = p => post('/datascreen-web/datascreen2NewbornLambStatistics/queryForPageList',p);
// 加工大屏---新增公司位置
const datascreen15AddCompany = p => post('/datascreen-web/datascreen15Company/doInsert',p);
// 加工大屏---修改公司位置
const datascreen15EditCompany = p => post('/datascreen-web/datascreen15Company/doUpdate',p);
// 加工大屏---公司列表
const datascreen15queryForList = p => post('/datascreen-web/datascreen15Company/queryForList',p);
// 加工大屏---公司删除
const datascreen15DelCompany = p => post('/datascreen-web/datascreen15Company/doDeleteById',p);
// 加工大屏---大屏主数据：保存
const datascreen15DoSave = p => post('/datascreen-web/datascreen15/doSave',p);
// 加工大屏---大屏主数据：查询
const datascreen15LoadDataBO = p => post('/datascreen-web/datascreen15/loadDataBO',p);

//马铃薯大屏---大屏主数据：保存
const datascreen3DoSave = p => post('/datascreen-web/datascreen3/doSave',p);

//马铃薯大屏---大屏主数据：查询
const datascreen3LoadDataBO = p => post('/datascreen-web/datascreen3/loadDataBO',p);

// 马铃薯大屏----物联网摄像头-新增
const doInsertDatascreen3Camera = p => post('/datascreen-web/datascreen3Camera/doInsert',p);
// 马铃薯大屏----物联网摄像头-关联
const datascreen3CameraQueryForPageList = p => post('/datascreen-web/datascreen3Camera/queryForPageList',p);
// 马铃薯大屏----物联网摄像头-取消关联
const datascreen3CameraQueryDoDeleteById = p => post('/datascreen-web/datascreen3Camera/doDeleteById',p);

// 马铃薯大屏----物联网传感器-关联
const doInsertDatascreen3Sensor = p => post('/datascreen-web/datascreen3Sensor/doInsert',p);
// 马铃薯大屏----物联网传感器-查询
const datascreen3SensorQueryForPageList = p => post('/datascreen-web/datascreen3Sensor/queryForPageList',p);
// 马铃薯大屏----物联网传感器-取消关联
const doDeleteByIdQueryForPageList = p => post('/datascreen-web/datascreen3Sensor/doDeleteById',p);

// 马铃薯大屏----物联网-关联
const datascreenDeviceEnterpriseRelationDoInsert = p => post('/datascreen-web/datascreenDeviceEnterpriseRelation/doInsert',p);
// 马铃薯大屏----物联网-查询
const datascreenDeviceEnterpriseRelationueryForPageList = p => post('/datascreen-web/datascreenDeviceEnterpriseRelation/queryForPageList ',p);
// 马铃薯大屏----物联网-取消关联
const datascreenDeviceEnterpriseRelatiDoDeleteById = p => post('/datascreen-web/datascreenDeviceEnterpriseRelation/doDeleteById',p);

// 加工产业大屏----乡镇地图配置-查询
const datascreen15TownshipMapConfQueryForPageList = p => post('/datascreen-web/datascreen15TownshipMapConf/queryForPageList',p);
// 加工产业大屏----乡镇地图配置-新增
const datascreen15TownshipMapConfDoInsert = p => post('/datascreen-web/datascreen15TownshipMapConf/doInsert',p);
// 加工产业大屏----乡镇地图配置-修改
const datascreen15TownshipMapConfDoUpdate = p => post('/datascreen-web/datascreen15TownshipMapConf/doUpdate',p);
// 加工产业大屏----乡镇地图配置-删除
const datascreen15TownshipMapConfDoDeleteById = p => formDataPost('/datascreen-web/datascreen15TownshipMapConf/doDeleteById',p);

// 鸡大屏大屏----乡镇地图配置-查询
const datascreen9TownshipMapConfQueryForPageList = p => post('/datascreen-web/datascreen9TownshipMapConf/queryForPageList',p);
// 鸡大屏大屏----乡镇地图配置-新增
const datascreen9TownshipMapConfDoInsert = p => post('/datascreen-web/datascreen9TownshipMapConf/doInsert',p);
// 鸡大屏大屏----乡镇地图配置-修改
const datascreen9TownshipMapConfDoUpdate = p => post('/datascreen-web/datascreen9TownshipMapConf/doUpdate',p);
// 鸡大屏大屏----乡镇地图配置-删除
const datascreen9TownshipMapConfDoDeleteById = p => formDataPost('/datascreen-web/datascreen9TownshipMapConf/doDeleteById',p);




//马铃薯---企业信息：新增接口
const datascreen3EnterpriseMapInfoDoBatchInsert = p => post('/datascreen-web/datascreen3EnterpriseMapInfo/doInsert',p);
//马铃薯---企业信息：修改接口
const datascreen3EnterpriseMapInfoDoUpdate = p => post('/datascreen-web/datascreen3EnterpriseMapInfo/doUpdate',p);
//马铃薯---企业信息：删除接口
const datascreen3EnterpriseMapInfoDoDeleteById = p => post('/datascreen-web/datascreen3EnterpriseMapInfo/doDeleteById',p);
//马铃薯---企业信息：查询接口
const datascreen3EnterpriseMapInfoQueryForPageList = p => post('/datascreen-web/datascreen3EnterpriseMapInfo/queryForPageList',p);

//马铃薯大屏---产业面积统计-乡镇：新增
const datascreen3TownshipAreaStatisticsDoInsert = p => post('/datascreen-web/datascreen3TownshipAreaStatistics/doInsert',p);
//马铃薯大屏---产业面积统计-乡镇：修改
const datascreen3TownshipAreaStatisticsDoUpdate = p => post('/datascreen-web/datascreen3TownshipAreaStatistics/doUpdate',p);
//马铃薯大屏---产业面积统计-乡镇：删除
const datascreen3TownshipAreaStatisticsDoDeleteById = p => post('/datascreen-web/datascreen3TownshipAreaStatistics/doDeleteById',p);
//马铃薯大屏---产业面积统计-乡镇：分页查询
const datascreen3TownshipAreaStatisticsQueryForPageList = p => post('/datascreen-web/datascreen3TownshipAreaStatistics/queryForPageList',p);

//马铃薯大屏--乡镇地图配置：分页查询
const datascreen3TownshipMapConfQueryForPageList = p => post('/datascreen-web/datascreen3TownshipMapConf/queryForPageList',p);
//马铃薯大屏--乡镇地图配置：新增
const datascreen3TownshipMapConfDoInsert = p => post('/datascreen-web/datascreen3TownshipMapConf/doInsert',p);
//马铃薯大屏--乡镇地图配置：修改
const datascreen3TownshipMapConfDoUpdate = p => post('/datascreen-web/datascreen3TownshipMapConf/doUpdate',p);
//马铃薯大屏--乡镇地图配置：删除
const datascreen3TownshipMapConfDoDeleteById = p => formDataPost('/datascreen-web/datascreen3TownshipMapConf/doDeleteById',p);

//马铃薯大屏---产业面积统计龙头企业：：新增
const datascreen3EnterpriseAreaStatisticsDoInsert = p => post('/datascreen-web/datascreen3EnterpriseAreaStatistics/doInsert',p);
//马铃薯大屏---产业面积统计龙头企业：：修改
const datascreen3EnterpriseAreaStatisticsDoUpdate = p => post('/datascreen-web/datascreen3EnterpriseAreaStatistics/doUpdate',p);
//马铃薯大屏---产业面积统计龙头企业：：删除
const datascreen3EnterpriseAreaStatisticsDoDeleteById = p => post('/datascreen-web/datascreen3EnterpriseAreaStatistics/doDeleteById',p);
//马铃薯大屏---产业面积统计龙头企业：：分页查询
const datascreen3EnterpriseAreaStatisticsQueryForPageList = p => post('/datascreen-web/datascreen3EnterpriseAreaStatistics/queryForPageList',p);


//大地种业马铃薯 编辑
const datascreen5DoSave = p => post('/datascreen-web/datascreen5/doSave',p);
//大地种业马铃薯---查询
const datascreen5LoadDataBO = p => post('/datascreen-web/datascreen5/loadDataBO',p);


//大地种业马铃薯-年度总产量|平均亩产--新增
const datascreen5AnnualTotalYieldDoInsert = p => post('/datascreen-web/datascreen5AnnualTotalYield/doInsert',p);
//大地种业马铃薯-年度总产量|平均亩产- 修改
const datascreen5AnnualTotalYieldDoUpdate = p => post('/datascreen-web/datascreen5AnnualTotalYield/doUpdate',p);
//大地种业马铃薯-年度总产量|平均亩产- 删除
const datascreen5AnnualTotalYieldDoDeleteById = p => post('/datascreen-web/datascreen5AnnualTotalYield/doDeleteById',p);
//大地种业马铃薯-年度总产量|平均亩产- 查询
const datascreen5AnnualTotalYieldQueryForPageList = p => post('/datascreen-web/datascreen5AnnualTotalYield/queryForPageList',p);

//大地种业马铃薯-基地人员-  新增
const datascreen5JobDoInsert = p => post('/datascreen-web/datascreen5Job/doInsert',p);
//大地种业马铃薯-基地人员- 修改
const datascreen5JobDoUpdate = p => post('/datascreen-web/datascreen5Job/doUpdate',p);
//大地种业马铃薯-基地人员- 删除
const datascreen5JobDoDeleteById = p => post('/datascreen-web/datascreen5Job/doDeleteById',p);
//大地种业马铃薯-基地人员- 分页查询
const datascreen5JobQueryForPageList = p => post('/datascreen-web/datascreen5Job/queryForPageList',p);


//大地种业马铃薯-基地农机：-  新增
const datascreen5MachineryDoInsert = p => post('/datascreen-web/datascreen5Machinery/doInsert',p);
//大地种业马铃薯-基地农机：- 修改
const datascreen5MachineryDoUpdate = p => post('/datascreen-web/datascreen5Machinery/doUpdate',p);
//大地种业马铃薯-基地农机：- 删除
const datascreen5MachineryDoDeleteById = p => post('/datascreen-web/datascreen5Machinery/doDeleteById',p);
//大地种业马铃薯-基地农机：- 分页查询
const datascreen5MachineryForPageList = p => post('/datascreen-web/datascreen5Machinery/queryForPageList',p);

//大地种业马铃薯-地块信息-  新增
const datascreen5MassifDoInsert = p => post('/datascreen-web/datascreen5Massif/doInsert',p);
//大地种业马铃薯-地块信息- 修改
const datascreen5MassifDoUpdate = p => post('/datascreen-web/datascreen5Massif/doUpdate',p);
//大地种业马铃薯-地块信息- 删除
const datascreen5MassifDoDeleteById = p => post('/datascreen-web/datascreen5Massif/doDeleteById',p);
//大地种业马铃薯-地块信息- 分页查询
const datascreen5MassifQueryForPageList = p => post('/datascreen-web/datascreen5Massif/queryForPageList',p);

//大地种业马铃薯-任务实时进度-  新增
const datascreen5TaskDoInsert = p => post('/datascreen-web/datascreen5Task/doInsert',p);
//大地种业马铃薯-任务实时进度- 修改
const datascreen5TaskDoUpdate = p => post('/datascreen-web/datascreen5Task/doUpdate',p);
//大地种业马铃薯-任务实时进度- 删除
const datascreen5TaskDoDeleteById = p => post('/datascreen-web/datascreen5Task/doDeleteById',p);
//大地种业马铃薯-任务实时进度- 分页查询
const datascreen5TaskQueryForPageList = p => post('/datascreen-web/datascreen5Task/queryForPageList',p);


//大地种业马铃薯-农事预警-  新增
const datascreen5WarnDoInsert = p => post('/datascreen-web/datascreen5Warn/doInsert',p);
//大地种业马铃薯-农事预警- 修改
const datascreen5WarnDoUpdate = p => post('/datascreen-web/datascreen5Warn/doUpdate',p);
//大地种业马铃薯-农事预警- 删除
const datascreen5WarnDoDeleteById = p => post('/datascreen-web/datascreen5Warn/doDeleteById',p);
//大地种业马铃薯-农事预警- 分页查询
const datascreen5WarnQueryForPageList = p => post('/datascreen-web/datascreen5Warn/queryForPageList',p);

//明杰马铃薯 编辑
const datascreen6DoSave = p => post('/datascreen-web/datascreen6/doSave',p);
//明杰马铃薯---查询
const datascreen6LoadDataBO = p => post('/datascreen-web/datascreen6/loadDataBO',p);

//明杰马铃薯-年度总产量|平均亩产--新增
const datascreen6AnnualTotalYieldDoInsert = p => post('/datascreen-web/datascreen6AnnualTotalYield/doInsert',p);
//明杰马铃薯-年度总产量|平均亩产- 修改
const datascreen6AnnualTotalYieldDoUpdate = p => post('/datascreen-web/datascreen6AnnualTotalYield/doUpdate',p);
//明杰马铃薯-年度总产量|平均亩产- 删除
const datascreen6AnnualTotalYieldDoDeleteById = p => post('/datascreen-web/datascreen6AnnualTotalYield/doDeleteById',p);
//明杰马铃薯-年度总产量|平均亩产- 查询
const datascreen6AnnualTotalYieldQueryForPageList = p => post('/datascreen-web/datascreen6AnnualTotalYield/queryForPageList',p);


//明杰马铃薯-基地人员-  新增
const datascreen6JobDoInsert = p => post('/datascreen-web/datascreen6Job/doInsert',p);
//明杰马铃薯-基地人员- 修改
const datascreen6JobDoUpdate = p => post('/datascreen-web/datascreen6Job/doUpdate',p);
//明杰马铃薯-基地人员- 删除
const datascreen6JobDoDeleteById = p => post('/datascreen-web/datascreen6Job/doDeleteById',p);
//明杰马铃薯-基地人员- 分页查询
const datascreen6JobQueryForPageList = p => post('/datascreen-web/datascreen6Job/queryForPageList',p);


//明杰马铃薯-基地农机：-  新增
const datascreen6MachineryDoInsert = p => post('/datascreen-web/datascreen6Machinery/doInsert',p);
//明杰马铃薯-基地农机：- 修改
const datascreen6MachineryDoUpdate = p => post('/datascreen-web/datascreen6Machinery/doUpdate',p);
//明杰马铃薯-基地农机：- 删除
const datascreen6MachineryDoDeleteById = p => post('/datascreen-web/datascreen6Machinery/doDeleteById',p);
//明杰马铃薯-基地农机：- 分页查询
const datascreen6MachineryForPageList = p => post('/datascreen-web/datascreen6Machinery/queryForPageList',p);

//明杰马铃薯-地块信息-  新增
const datascreen6MassifDoInsert = p => post('/datascreen-web/datascreen6Massif/doInsert',p);
//明杰马铃薯-地块信息- 修改
const datascreen6MassifDoUpdate = p => post('/datascreen-web/datascreen6Massif/doUpdate',p);
//明杰马铃薯-地块信息- 删除
const datascreen6MassifDoDeleteById = p => post('/datascreen-web/datascreen6Massif/doDeleteById',p);
//明杰马铃薯-地块信息- 分页查询
const datascreen6MassifQueryForPageList = p => post('/datascreen-web/datascreen6Massif/queryForPageList',p);


//明杰马铃薯-任务实时进度-  新增
const datascreen6TaskDoInsert = p => post('/datascreen-web/datascreen6Task/doInsert',p);
//明杰马铃薯-任务实时进度- 修改
const datascreen6TaskDoUpdate = p => post('/datascreen-web/datascreen6Task/doUpdate',p);
//明杰马铃薯-任务实时进度- 删除
const datascreen6TaskDoDeleteById = p => post('/datascreen-web/datascreen6Task/doDeleteById',p);
//明杰马铃薯-任务实时进度- 分页查询
const datascreen6TaskQueryForPageList = p => post('/datascreen-web/datascreen6Task/queryForPageList',p);

//明杰马铃薯-农事预警-  新增
const datascreen6WarnDoInsert = p => post('/datascreen-web/datascreen6Warn/doInsert',p);
//明杰马铃薯-农事预警- 修改
const datascreen6WarnDoUpdate = p => post('/datascreen-web/datascreen6Warn/doUpdate',p);
//明杰马铃薯-农事预警- 删除
const datascreen6WarnDoDeleteById = p => post('/datascreen-web/datascreen6Warn/doDeleteById',p);
//明杰马铃薯-农事预警- 分页查询
const datascreen6WarnQueryForPageList = p => post('/datascreen-web/datascreen6Warn/queryForPageList',p);


//鱼河马铃薯 编辑
const datascreen4DoSave = p => post('/datascreen-web/datascreen4/doSave',p);
//鱼河马铃薯---查询
const datascreen4LoadDataBO = p => post('/datascreen-web/datascreen4/loadDataBO',p);

//鱼河马铃薯-年度总产量|平均亩产--新增
const datascreen4AnnualTotalYieldDoInsert = p => post('/datascreen-web/datascreen4AnnualTotalYield/doInsert',p);
//鱼河马铃薯-年度总产量|平均亩产- 修改
const datascreen4AnnualTotalYieldYieldDoUpdate = p => post('/datascreen-web/datascreen4AnnualTotalYield/doUpdate',p);
//鱼河马铃薯-年度总产量|平均亩产- 删除
const datascreen4AnnualTotalYieldYieldDoDeleteById = p => post('/datascreen-web/datascreen4AnnualTotalYield/doDeleteById',p);
//鱼河马铃薯-年度总产量|平均亩产- 查询
const datascreen4AnnualTotalYieldQueryForPageList = p => post('/datascreen-web/datascreen4AnnualTotalYield/queryForPageList',p);


//鱼河马铃薯-任务实时进度-  新增
const datascreen4TaskDoInsert = p => post('/datascreen-web/datascreen4Task/doInsert',p);
//鱼河马铃薯-任务实时进度- 修改
const datascreen4TaskDoUpdate = p => post('/datascreen-web/datascreen4Task/doUpdate',p);
//鱼河马铃薯-任务实时进度- 删除
const datascreen4TaskDoDeleteById = p => post('/datascreen-web/datascreen4Task/doDeleteById',p);
//鱼河马铃薯-任务实时进度- 分页查询
const datascreen4TaskQueryForPageList = p => post('/datascreen-web/datascreen4Task/queryForPageList',p);


//鱼河马铃薯-农事预警-  新增
const datascreen4WarnDoInsert = p => post('/datascreen-web/datascreen4Warn/doInsert',p);
//鱼河马铃薯-农事预警- 修改
const datascreen4WarnDoUpdate = p => post('/datascreen-web/datascreen4Warn/doUpdate',p);
//鱼河马铃薯-农事预警- 删除
const datascreen4WarnDoDeleteById = p => post('/datascreen-web/datascreen4Warn/doDeleteById',p);
//鱼河马铃薯-农事预警- 分页查询
const datascreen4WarnQueryForPageList = p => post('/datascreen-web/datascreen4Warn/queryForPageList',p);

//小米  编辑
const datascreen11DoSave = p => post('/datascreen-web/datascreen11/doSave',p);
//小米--查询
const datascreen11LoadDataBO = p => post('/datascreen-web/datascreen11/loadDataBO',p);

//小米-年度总产量|平均亩产--新增
const datascreen11AnnualTotalYieldDoInsert = p => post('/datascreen-web/datascreen11AnnualTotalYield/doInsert',p);
//小米-年度总产量|平均亩产- 修改
const datascreen11AnnualTotalYieldDoUpdate = p => post('/datascreen-web/datascreen11AnnualTotalYield/doUpdate',p);
//小米-年度总产量|平均亩产- 删除
const datascreen11AnnualTotalYieldDoDeleteById = p => post('/datascreen-web/datascreen11AnnualTotalYield/doDeleteById',p);
//小米-年度总产量|平均亩产- 查询
const datascreen11AnnualTotalYieldQueryForPageList = p => post('/datascreen-web/datascreen11AnnualTotalYield/queryForPageList',p);


//小米-预警--新增
const datascreen11WarnDoInsert = p => post('/datascreen-web/datascreen11Warn/doInsert',p);
//小米-预警- 修改
const datascreen11WarnDoUpdate = p => post('/datascreen-web/datascreen11Warn/doUpdate',p);
//小米-预警- 删除
const datascreen11WarnDoDeleteById = p => post('/datascreen-web/datascreen11Warn/doDeleteById',p);
//小米-预警- 查询
const datascreen11WarnQueryForPageList = p => post('/datascreen-web/datascreen11Warn/queryForPageList',p);

//小米-种植日志--新增
const datascreen11PlantingLogDoInsert = p => post('/datascreen-web/datascreen11PlantingLog/doInsert',p);
//小米-种植日志- 修改
const datascreen11PlantingLogDoUpdate = p => post('/datascreen-web/datascreen11PlantingLog/doUpdate',p);
//小米-种植日志- 删除
const datascreen11PlantingLogDoDeleteById = p => post('/datascreen-web/datascreen11PlantingLog/doDeleteById',p);
//小米-种植日志- 查询
const datascreen11PlantingLogQueryForPageList = p => post('/datascreen-web/datascreen11PlantingLog/queryForPageList',p);


//小米-一升小米订单统计：--新增
const datascreen11OrderDoInsert = p => post('/datascreen-web/datascreen11Order/doInsert',p);
//小米-一升小米订单统计：- 修改
const datascreen11OrderDoUpdate = p => post('/datascreen-web/datascreen11Order/doUpdate',p);
//小米-一升小米订单统计：- 删除
const datascreen11OrderDoDeleteById = p => post('/datascreen-web/datascreen11Order/doDeleteById',p);
//小米-一升小米订单统计：- 查询
const datascreen11OrderQueryForPageList = p => post('/datascreen-web/datascreen11Order/queryForPageList',p);

//农机  编辑
const datascreen12DoSave = p => post('/datascreen-web/datascreen12/doSave',p);
//农机--查询
const datascreen12LoadDataBO = p => post('/datascreen-web/datascreen12/loadDataBO',p);

//共享农机-乡镇地图配置-查询
const datascreen12TownshipMapConfQueryForPageList = p => post('/datascreen-web/datascreen12TownshipMapConf/queryForPageList',p);
//共享农机-乡镇地图配置-新增
const datascreen12TownshipMapConfDoInsert = p => post('/datascreen-web/datascreen12TownshipMapConf/doInsert',p);
//共享农机-乡镇地图配置-修改
const datascreen12TownshipMapConfDoUpdate = p => post('/datascreen-web/datascreen12TownshipMapConf/doUpdate',p);
//共享农机-乡镇地图配置-删除
const datascreen12TownshipMapConfDoDeleteById = p => formDataPost('/datascreen-web/datascreen12TownshipMapConf/doDeleteById',p);


//农机-共享农机订单大厅统计--新增
const datascreen12ShareMachineryOrderStatisticsDoInsert = p => post('/datascreen-web/datascreen12ShareMachineryOrderStatistics/doInsert',p);
//农机-共享农机订单大厅统计- 修改
const datascreen12ShareMachineryOrderStatisticsDoUpdate = p => post('/datascreen-web/datascreen12ShareMachineryOrderStatistics/doUpdate',p);
//农机-共享农机订单大厅统计- 删除
const datascreen12ShareMachineryOrderStatisticsDoDeleteById = p => post('/datascreen-web/datascreen12ShareMachineryOrderStatistics/doDeleteById',p);
//农机-共享农机订单大厅统计- 查询
const datascreen12ShareMachineryOrderStatisticsQueryForPageList = p => post('/datascreen-web/datascreen12ShareMachineryOrderStatistics/queryForPageList',p);


//农机-乡镇农机基本信息统计--新增
const datascreen12TownshipAgriculturalMachineryDoInsert = p => post('/datascreen-web/datascreen12TownshipAgriculturalMachinery/doInsert',p);
//农机-乡镇农机基本信息统计- 修改
const datascreen12TownshipAgriculturalMachineryDoUpdate = p => post('/datascreen-web/datascreen12TownshipAgriculturalMachinery/doUpdate',p);
//农机-乡镇农机基本信息统计- 删除
const datascreen12TownshipAgriculturalMachineryDoDeleteById = p => post('/datascreen-web/datascreen12TownshipAgriculturalMachinery/doDeleteById',p);
//农机-乡镇农机基本信息统计- 查询
const datascreen12TownshipAgriculturalMachineryQueryForPageList = p => post('/datascreen-web/datascreen12TownshipAgriculturalMachinery/queryForPageList',p);

//农机-全区参与社会化服务的农机统计- 查询
const datascreen12AgriculturalStatisticsQueryForPageList = p => post('/datascreen-web/datascreen12AgriculturalStatistics/queryForPageList',p);
//农机-全区参与社会化服务的农机统计- 新增
const datascreen12AgriculturalStatisticsDoInsert = p => post('/datascreen-web/datascreen12AgriculturalStatistics/doInsert',p);
//农机-全区参与社会化服务的农机统计- 修改
const datascreen12AgriculturalStatisticsDoUpdate = p => post('/datascreen-web/datascreen12AgriculturalStatistics/doUpdate',p);
//农机-全区参与社会化服务的农机统计- 删除
const datascreen12AgriculturalStatisticsDoDeleteById = p => formDataPost('/datascreen-web/datascreen12AgriculturalStatistics/doDeleteById',p);


//农机-作业类型面积统计--新增
const datascreen12AreaOfOperationTypeStatisticsDoInsert = p => post('/datascreen-web/datascreen12AreaOfOperationTypeStatistics/doInsert',p);
//农机-作业类型面积统计- 修改
const datascreen12AreaOfOperationTypeStatisticsDoUpdate = p => post('/datascreen-web/datascreen12AreaOfOperationTypeStatistics/doUpdate',p);
//农机-作业类型面积统计- 删除
const datascreen12AreaOfOperationTypeStatisticsDoDeleteById = p => post('/datascreen-web/datascreen12AreaOfOperationTypeStatistics/doDeleteById',p);
//农机-作业类型面积统计- 查询
const datascreen12AreaOfOperationTypeStatisticsQueryForPageList = p => post('/datascreen-web/datascreen12AreaOfOperationTypeStatistics/queryForPageList',p);

//农机-北部乡镇作业面积统计--新增
const datascreen12NorthTownshipOperationAreaStatisticsDoInsert = p => post('/datascreen-web/datascreen12NorthTownshipOperationAreaStatistics/doInsert',p);
//农机-北部乡镇作业面积统计- 修改
const datascreen12NorthTownshipOperationAreaStatisticsDoUpdate = p => post('/datascreen-web/datascreen12NorthTownshipOperationAreaStatistics/doUpdate',p);
//农机-北部乡镇作业面积统计- 删除
const datascreen12NorthTownshipOperationAreaStatisticsDoDeleteById = p => post('/datascreen-web/datascreen12NorthTownshipOperationAreaStatistics/doDeleteById',p);
//农机-北部乡镇作业面积统计- 查询
const datascreen12NorthTownshipOperationAreaStatisticsQueryForPageList = p => post('/datascreen-web/datascreen12NorthTownshipOperationAreaStatistics/queryForPageList',p);

//农机-南部乡镇作业面积统计--新增
const datascreen12SouthTownshipOperationAreaStatisticsDoInsert = p => post('/datascreen-web/datascreen12SouthTownshipOperationAreaStatistics/doInsert',p);
//农机-南部乡镇作业面积统计- 修改
const datascreen12SouthTownshipOperationAreaStatisticsDoUpdate = p => post('/datascreen-web/datascreen12SouthTownshipOperationAreaStatistics/doUpdate',p);
//农机-南部乡镇作业面积统计- 删除
const datascreen12SouthTownshipOperationAreaStatisticsDoDeleteById = p => post('/datascreen-web/datascreen12SouthTownshipOperationAreaStatistics/doDeleteById',p);
//农机-南部乡镇作业面积统计- 查询
const datascreen12SouthTownshipOperationAreaStatisticsQueryForPageList = p => post('/datascreen-web/datascreen12SouthTownshipOperationAreaStatistics/queryForPageList',p);

//大屏-土豆-品种-列表
const datascreen13VarietiesQueryForPageList = p => post('/datascreen-web/datascreen3Breed/queryForPageList',p);
//大屏-土豆-品种-新增
const datascreen13VarietiesDoInsert = p => post('/datascreen-web/datascreen3Breed/doInsert',p);

//大屏-土豆-品种-修改
const datascreen13VarietiesDoUpdate = p => post('/datascreen-web/datascreen3Breed/doUpdate',p);

//大屏-土豆-品种-删除
const datascreen13VarietiesDoDeleteById = p => post('/datascreen-web/datascreen3Breed/doBatchDelete',p);



//仓储  编辑
const datascreen10DoSave = p => post('/datascreen-web/datascreen10/doSave',p);
//仓储--查询
const datascreen10LoadDataBO = p => post('/datascreen-web/datascreen10/loadDataBO',p);

//仓储-仓储贮藏用量统计：--新增
const datascreen10ConsumptionStatisticsDoInsert = p => post('/datascreen-web/datascreen10ConsumptionStatistics/doInsert',p);
//仓储-仓储贮藏用量统计：- 修改
const datascreen10ConsumptionStatisticsDoUpdate = p => post('/datascreen-web/datascreen10ConsumptionStatistics/doUpdate',p);
//仓储-仓储贮藏用量统计：- 删除
const datascreen10ConsumptionStatisticsDoDeleteById = p => post('/datascreen-web/datascreen10ConsumptionStatistics/doDeleteById',p);
//仓储-仓储贮藏用量统计：- 查询
const datascreen10ConsumptionStatisticsQueryForPageList = p => post('/datascreen-web/datascreen10ConsumptionStatistics/queryForPageList',p);


//仓储-仓储企业统计：--新增
const datascreen10StorageEnterpriseDoInsert = p => post('/datascreen-web/datascreen10StorageEnterprise/doInsert',p);
//仓储-仓储企业统计：- 修改
const datascreen10StorageEnterpriseDoUpdate = p => post('/datascreen-web/datascreen10StorageEnterprise/doUpdate',p);
//仓储-仓储企业统计：- 删除
const datascreen10StorageEnterpriseDoDeleteById = p => post('/datascreen-web/datascreen10StorageEnterprise/doDeleteById',p);
//仓储-仓储企业统计：- 查询
const datascreen10StorageEnterpriseQueryForPageList = p => post('/datascreen-web/datascreen10StorageEnterprise/queryForPageList',p);

//大屏-肉牛-新增基本信息
const datascreen16DoInsert = p => post('datascreen-web/datascreen16/doInsert',p);
//大屏-肉牛-基本信息详情
const datascreen16QueryByParams = p => post('datascreen-web/datascreen16/queryByParams',p);

//大屏-肉牛-牛饲养品种-列表
const datascreen16VarietiesQueryForPageList = p => post('/datascreen-web/datascreen16Varieties/queryForPageList',p);
//大屏-肉牛-牛饲养品种-新增
const datascreen16VarietiesDoInsert = p => post('/datascreen-web/datascreen16Varieties/doInsert',p);

//大屏-肉牛-牛饲养品种-修改
const datascreen16VarietiesDoUpdate = p => post('/datascreen-web/datascreen16Varieties/doUpdate',p);

//大屏-肉牛-牛饲养品种-删除
const datascreen16VarietiesDoDeleteById = p => post('/datascreen-web/datascreen16Varieties/doBatchDelete',p);


//大屏-肉牛-人员批量-列表
const  datascreen16JobQueryForPageList= p => post('/datascreen-web/datascreen16Job/queryForPageList',p);
//大屏-肉牛-人员批量-新增
const datascreen16JobDoInsert = p => post('/datascreen-web/datascreen16Job/doInsert',p);

//大屏-肉牛-人员批量-修改
const datascreen16JobDoUpdate = p => post('/datascreen-web/datascreen16Job/doUpdate',p);

//大屏-肉牛-人员批量-删除
const datascreen16JobDoDeleteById = p => post('/datascreen-web/datascreen16Job/doBatchDelete',p);


//大屏-肉牛-人员批量-列表
const  datascreen16PriceQueryForPageList= p => post('/datascreen-web/datascreen16Price/queryForPageList',p);

//大屏-肉牛-人员批量-新增
const datascreen16PriceDoInsert = p => post('/datascreen-web/datascreen16Price/doInsert',p);

//大屏-肉牛-人员批量-修改
const datascreen16PriceDoUpdate = p => post('/datascreen-web/datascreen16Price/doUpdate',p);

//大屏-肉牛-人员批量-删除
const datascreen16PriceDoDeleteById = p => post('/datascreen-web/datascreen16Price/doBatchDelete',p);

//大屏-肉牛-各乡镇列表-列表
const  datascreen16TownQueryForPageList= p => post('/datascreen-web/datascreen16Inventory/queryForPageList',p);

//大屏-肉牛-各乡镇列表-新增
const datascreen16TownDoInsert = p => post('/datascreen-web/datascreen16Inventory/doInsert',p);

//大屏-肉牛-各乡镇列表-修改
const datascreen16TownDoUpdate = p => post('/datascreen-web/datascreen16Inventory/doUpdate',p);

//大屏-肉牛-各乡镇列表-删除
const datascreen16TownDoDeleteById = p => post('/datascreen-web/datascreen16Inventory/doBatchDelete',p);

//大屏-肉牛-月进栏出栏量-列表
const  datascreen16StatisticsQueryForPageList= p => post('/datascreen-web/datascreen16Statistics/queryForPageList',p);

//大屏-肉牛-各乡镇列表-列表
const  datascreen16StatisticsMothQueryForPageList= p => post('/datascreen-web/datascreen16StatisticsMoth/queryForPageList',p);


//大屏-肉牛-出栏批量-新增
const datascreen16StatisticsDoInsert = p => post('/datascreen-web/datascreen16Statistics/doInsert',p);

//大屏-肉牛-出栏批量-新增
const datascreen16StatisticsMothDoInsert = p => post('/datascreen-web/datascreen16StatisticsMoth/doInsert',p);

//大屏-肉牛-出栏批量-修改
const datascreen16StatisticsMothDoUpdate = p => post('/datascreen-web/datascreen16StatisticsMoth/doUpdate',p);

//大屏-肉牛-出栏批量-删除
const datascreen16StatisticsMothDoDeleteById = p => formDataPost('/datascreen-web/datascreen16StatisticsMoth/doDeleteById',p);

//大屏-肉牛-出栏批量-修改
const datascreen16StatisticsDoUpdate = p => post('/datascreen-web/datascreen16Statistics/doUpdate',p);

//大屏-肉牛-出栏批量-删除
const datascreen16StatisticsDoDeleteById = p => post('/datascreen-web/datascreen16Statistics/doBatchDelete',p);

//大屏-大漠蔬菜-基础信息 编辑
const datascreen17DoUpdate = p => post('/datascreen-web/datascreen17/doUpdate',p);
//大屏-大漠蔬菜-基础信息 修改
const datascreen17QueryUniqueObject = p => post('/datascreen-web/datascreen17/queryUniqueObject',p);
//大屏-大漠蔬菜-乡镇设施蔬菜统计 新增
const datascreen17FacilitiesDoInsert = p => post('/datascreen-web/datascreen17Facilities/doInsert',p);
//大屏-大漠蔬菜-乡镇设施蔬菜统计 修改
const datascreen17FacilitiesDoUpdate = p => post('/datascreen-web/datascreen17Facilities/doUpdate',p);
//大屏-大漠蔬菜-乡镇设施蔬菜统计 查询
const datascreen17CooperativeQueryForPageList = p => post('/datascreen-web/datascreen17Facilities/queryForPageList',p);
//大屏-大漠蔬菜-乡镇设施蔬菜统计 删除
const datascreen17CooperativedoDoBatchDelete = p => post('/datascreen-web/datascreen17Facilities/doBatchDelete',p);

//大屏-大漠蔬菜-地图-新增 
const datascreen17WorkMapAdd = p => post('/datascreen-web/datascreen17WorkMap/add',p);
//大屏-大漠蔬菜-地图-修改 
const datascreen17WorkMapEdit = p => post('/datascreen-web/datascreen17WorkMap/edit',p);
//大屏-大漠蔬菜-地图-删除
const datascreen17WorkMapDeleteById = p => post('/datascreen-web/datascreen17WorkMap/deleteById',p);
// //大屏-大漠蔬菜-地图-查询
const datascreen17WorkMapQueryForPageList = p => post('/datascreen-web/datascreen17WorkMap/queryForPageList',p);

//大屏-俩品一标-新增
const datascreen17TrademarkDoInsert = p => post('/datascreen-web/datascreen17Trademark/doInsert',p);
//大屏-俩品一标-修改
const datascreen17TrademarkDoUpdate = p => post('/datascreen-web/datascreen17Trademark/doUpdate',p);
//大屏-俩品一标-删除
const datascreen17TrademarkDeleteById = p => post('/datascreen-web/datascreen17Trademark/doBatchDelete',p);
//大屏-俩品一标-查询
const datascreen17TrademarkQueryForPageList = p => post('/datascreen-web/datascreen17Trademark/queryForPageList',p);

//冷链仓储-农作物-新增
const datascreen10CropsDoInsert = p => post('/datascreen-web/datascreen10Crops/doInsert',p);
//冷链仓储-农作物-修改
const datascreen10CropsDoUpdate = p => post('/datascreen-web/datascreen10Crops/doUpdate',p);
//冷链仓储-农作物-查询
const datascreen10CropsQeryForPageList = p => post('/datascreen-web/datascreen10Crops/queryForPageList',p);
//冷链仓储-农作物-删除
const datascreen10CropsDoDeleteById = p => post('/datascreen-web/datascreen10Crops/doDeleteById',p);

//冷链仓储-仓储类型-新增
const datascreen10StoretypeDoInsert = p => post('/datascreen-web/datascreen10Storetype/doInsert',p);

//冷链仓储-仓储类型-编辑
const datascreen10StoretypeDoUpdate = p => post('/datascreen-web/datascreen10Storetype/doUpdate',p);

//冷链仓储-仓储类型-删除
const datascreen10StoretypeDoDeleteById = p => post('/datascreen-web/datascreen10Storetype/doDeleteById',p);

//冷链仓储-仓储类型-查询
const datascreen10StoretypeQueryForPageList = p => post('/datascreen-web/datascreen10Storetype/queryForPageList',p);

//冷链仓储-乡镇地图配置-查询
const datascreen10TownshipMapConfQueryForPageList = p => post('/datascreen-web/datascreen10TownshipMapConf/queryForPageList',p);
//冷链仓储-乡镇地图配置-新增
const datascreen10TownshipMapConfDoInsert = p => post('/datascreen-web/datascreen10TownshipMapConf/doInsert',p);
//冷链仓储-乡镇地图配置-修改
const datascreen10TownshipMapConfDoUpdate = p => post('/datascreen-web/datascreen10TownshipMapConf/doUpdate',p);
//冷链仓储-乡镇地图配置-删除
const datascreen10TownshipMapConfDoDeleteById = p => formDataPost('/datascreen-web/datascreen10TownshipMapConf/doDeleteById',p);



//冷链仓储-各乡镇冷链设施基础信息-编辑
const datascreen10TownshipColdchainInfoDoUpdate = p => post('/datascreen-web/datascreen10TownshipColdchainInfo/doUpdate',p);

//冷链仓储-各乡镇冷链设施基础信息-删除
const datascreen10TownshipColdchainInfoDoDeleteById = p => post('/datascreen-web/datascreen10TownshipColdchainInfo/doDeleteById ',p);

//冷链仓储-各乡镇冷链设施基础信息-查询
const datascreen10TownshipColdchainInfoQueryForPageList = p => post('/datascreen-web/datascreen10TownshipColdchainInfo/queryForPageList',p);

//冷链仓储-各乡镇冷链设施基础信息-新增
const datascreen10TownshipColdchainInfoDoInsert = p => post('/datascreen-web/datascreen10TownshipColdchainInfo/doInsert',p);



//大屏-大漠蔬菜-全区设施农业主要播种品种面积与产量统计分 新增
const datascreen17AllAreaSowingStatisticsQueryForPageList = p => post('/datascreen-web/datascreen17AllAreaSowingStatistics/queryForPageList',p);
//大屏-大漠蔬菜-全区设施农业主要播种品种面积与产量统计分 修改
const datascreen17AllAreaSowingStatisticsDoInsert = p => post('/datascreen-web/datascreen17AllAreaSowingStatistics/doInsert',p);
//大屏-大漠蔬菜-全区设施农业主要播种品种面积与产量统计分 查询
const datascreen17AllAreaSowingStatisticsDoUpdate = p => post('/datascreen-web/datascreen17AllAreaSowingStatistics/doUpdate',p);
//大屏-大漠蔬菜-全区设施农业主要播种品种面积与产量统计分 删除
const datascreen17AllAreaSowingStatisticsDoBatchDelete = p => post('/datascreen-web/datascreen17AllAreaSowingStatistics/doBatchDelete',p);


//大屏-大漠蔬菜-各乡镇设施蔬菜建设面积统计 新增
const  datascreen17TownshipsVegetableAreaStatisticsDoInsert= p => post('/datascreen-web/datascreen17TownshipsVegetableAreaStatistics/doInsert',p);
//大屏-大漠蔬菜-各乡镇设施蔬菜建设面积统计 修改
const datascreen17TownshipsVegetableAreaStatisticsDoUpdate = p => post('/datascreen-web/datascreen17TownshipsVegetableAreaStatistics/doUpdate',p);
//大屏-大漠蔬菜-各乡镇设施蔬菜建设面积统计 查询
const datascreen17TownshipsVegetableAreaStatisticsQueryForPageList = p => post('/datascreen-web/datascreen17TownshipsVegetableAreaStatistics/queryForPageList',p);
//大屏-大漠蔬菜-各乡镇设施蔬菜建设面积统计 删除
const datascreen17TownshipsVegetableAreaStatisticsDoBatchDelete = p => post('/datascreen-web/datascreen17TownshipsVegetableAreaStatistics/doBatchDelete',p);


//大屏-大漠蔬菜-全区设施农业主要播种品种价格区间 新增
const datascreen17AllAreaSowingPriceDoInsert = p => post('/datascreen-web/datascreen17AllAreaSowingPrice/doInsert',p);
//大屏-大漠蔬菜-全区设施农业主要播种品种价格区间 修改
const datascreen17AllAreaSowingPriceDoUpdate = p => post('/datascreen-web/datascreen17AllAreaSowingPrice/doUpdate',p);
//大屏-大漠蔬菜-全区设施农业主要播种品种价格区间 查询
const datascreen17AllAreaSowingPriceQueryForPageList = p => post('/datascreen-web/datascreen17AllAreaSowingPrice/queryForPageList',p);
//大屏-大漠蔬菜-全区设施农业主要播种品种价格区间 删除
const datascreen17AllAreaSowingPriceDoBatchDelete = p => post('/datascreen-web/datascreen17AllAreaSowingPrice/doBatchDelete',p);



//大屏-大漠蔬菜-全区设施农业主要播种品种面积与产量统计分 新增
const datascreen18TownshipsVegetableAreaStatisticsQueryForPageList = p => post('/datascreen-web/datascreen18TownshipsVegetableAreaStatistics/doInsert',p);
//大屏-大漠蔬菜-全区设施农业主要播种品种面积与产量统计分 修改
const datascreen18TownshipsVegetableAreaStatisticsDoInsert = p => post('/datascreen-web/datascreen18TownshipsVegetableAreaStatistics/doUpdate',p);
//大屏-大漠蔬菜-全区设施农业主要播种品种面积与产量统计分 查询
const datascreen18TownshipsVegetableAreaStatisticsDoUpdate = p => post('/datascreen-web/datascreen18TownshipsVegetableAreaStatistics/queryForPageList',p);
//大屏-大漠蔬菜-全区设施农业主要播种品种面积与产量统计分 删除
const datascreen18TownshipsVegetableAreaStatisticsDoBatchDelete = p => post('/datascreen-web/datascreen18TownshipsVegetableAreaStatistics/doBatchDelete',p);


// //大屏-大漠蔬菜-各乡镇设施蔬菜建设面积统计 新增
// const datascreen18AllAreaSowingStatisticsQueryForPageList = p => post('/datascreen-web/datascreen18AllAreaSowingStatistics/doInsert',p);
// //大屏-大漠蔬菜-各乡镇设施蔬菜建设面积统计 修改
// const datascreen18AllAreaSowingStatisticsDoInsert = p => post('/datascreen-web/datascreen18AllAreaSowingStatistics/doUpdate',p);
// //大屏-大漠蔬菜-各乡镇设施蔬菜建设面积统计 查询
// const datascreen18AllAreaSowingStatisticsDoUpdate = p => post('/datascreen-web/datascreen18AllAreaSowingStatistics/queryForPageList',p);
//大屏-大漠蔬菜-各乡镇设施蔬菜建设面积统计 删除
const datascreen18AllAreaSowingStatisticsDoBatchDelete = p => post('/datascreen-web/datascreen18AllAreaSowingStatistics/doBatchDelete',p);

//谷物杂粮-基础信息-查询
const datascreen18QueryById = p => formDataPost('/datascreen-web/datascreen18/queryById',p);
//谷物杂粮-基础信息-保存
const datascreen18DoInsert = p => post('/datascreen-web/datascreen18/doInsert',p);
//谷物杂粮-地图配置-查询
const datascreen18WorkMapQueryForPageList = p => post('/datascreen-web/datascreen18WorkMap/queryForPageList',p);
//谷物杂粮-地图配置-新增
const datascreen18WorkMapDoInsert = p => post('/datascreen-web/datascreen18WorkMap/doInsert',p);
//谷物杂粮-地图配置-修改
const datascreen18WorkMapDoUpdate = p => post('/datascreen-web/datascreen18WorkMap/doUpdate',p);
//谷物杂粮-地图配置-删除
const datascreen18WorkMapDoDeleteById = p => formDataPost('/datascreen-web/datascreen18WorkMap/doDeleteById',p);
//谷物杂粮-全区谷物杂粮播种统计-查询
const datascreen18AllAreaSowingStatisticsQueryForPageList = p => post('/datascreen-web/datascreen18AllAreaSowingStatistics/queryForPageList',p);
//谷物杂粮-全区谷物杂粮播种统计-新增
const datascreen18AllAreaSowingStatisticsDoInsert = p => post('/datascreen-web/datascreen18AllAreaSowingStatistics/doInsert',p);
//谷物杂粮-全区谷物杂粮播种统计-修改
const datascreen18AllAreaSowingStatisticsDoUpdate = p => post('/datascreen-web/datascreen18AllAreaSowingStatistics/doUpdate',p);
//谷物杂粮-全区谷物杂粮播种统计-删除
const datascreen18AllAreaSowingStatisticsDoDeleteById = p => formDataPost('/datascreen-web/datascreen18AllAreaSowingStatistics/doDeleteById',p);
//谷物杂粮-各乡镇谷物杂粮面积和产量统计-查询
const datascreen18TownshipsSowingStatisticsQueryForPageList = p => post('/datascreen-web/datascreen18TownshipsSowingStatistics/queryForPageList',p);
//谷物杂粮-各乡镇谷物杂粮面积和产量统计-新增
const datascreen18TownshipsSowingStatisticsDoInsert = p => post('/datascreen-web/datascreen18TownshipsSowingStatistics/doInsert',p);
//谷物杂粮-各乡镇谷物杂粮面积和产量统计-修改
const datascreen18TownshipsSowingStatisticsDoUpdate = p => post('/datascreen-web/datascreen18TownshipsSowingStatistics/doUpdate',p);
//谷物杂粮-各乡镇谷物杂粮面积和产量统计-删除
const datascreen18TownshipsSowingStatisticsDoDeleteById = p => formDataPost('/datascreen-web/datascreen18TownshipsSowingStatistics/doDeleteById',p);
//谷物杂粮-全区谷物杂粮主要播种品种价格区间 新增
const datascreen18AllAreaSowingPriceDoInsert = p => post('/datascreen-web/datascreen18AllAreaSowingPrice/doInsert',p);
//谷物杂粮-全区谷物杂粮主要播种品种价格区间 修改
const datascreen18AllAreaSowingPriceDoUpdate = p => post('/datascreen-web/datascreen18AllAreaSowingPrice/doUpdate',p);
//谷物杂粮-全区谷物杂粮主要播种品种价格区间 查询
const datascreen18AllAreaSowingPriceQueryForPageList = p => post('/datascreen-web/datascreen18AllAreaSowingPrice/queryForPageList',p);
//谷物杂粮-全区谷物杂粮主要播种品种价格区间 删除
const datascreen18AllAreaSowingPriceDoDeleteById = p => formDataPost('/datascreen-web/datascreen18AllAreaSowingPrice/doDeleteById',p);


//GPS
const gpsDoInsert = p => post('/agriculture-web/agriculture/iotDevGps/doInsert',p);

const gpsDoUpdate = p => post('/agriculture-web/agriculture/iotDevGps/doUpdate',p);

const gpsDoDeleteById = p => formDataPost('/agriculture-web/agriculture/iotDevGps/doDeleteById',p);

const gpsQueryForPageList = p => post('/agriculture-web/agriculture/iotDevGps/queryForPageList',p);

export default {
    loadDataBO,
    doSaveInfo,
    doInsertEgg,
    queryForPageListEgg,
    doDeleteByIdEgg,
    doInsertFooter,
    queryForPageListFooter,
    doDeleteByIdFooter,
    doInsertDisease,
    queryForPageListDisease,
    doDeleteByIdDisease,
    loadDataBOSheepBasicInformation1,
    doSaveSheepBasicInformation1,
    doInsertTownship1,
    doDeleteByIdTownship1,
    queryForPageListTownship1,
    queryForPageListEnterprise1,
    doInsertAdd,
    doInsertEdit,
    doDeleteByIdDelete1,
    datascreen1SheepPriceDoInsert,
    datascreen1SheepPriceDoUpdate,
    datascreen1SheepPriceDoDeleteById,
    datascreen1SheepPriceQueryForPageList,
    datascreen1SellmapInfoQueryForPageList,
    datascreen1SellmapInfoDoInsert,
    datascreen1SellmapInfoDoDeleteById,
    datascreen1ExponentialGraphQueryForPageList,
    datascreen1ExponentialGraphDoInsert,
    datascreen1ExponentialGraphDoUpdate,
    datascreen1ExponentialGraphDoDeleteById,
    datascreen1AvgPriceDoInsert,
    datascreen1AvgPriceDoUpdate,
    datascreen1AvgPriceDoDeleteById,
    datascreen1AvgPriceQueryForPageList,
    datascreen1SheepPriceQueryForDoUpdate,
    datascreen1WhirlyQueryForPageList,
    datascreen1WhirlyQueryForDoInsert,
    datascreen1WhirlyQueryForDoUpdate,
    datascreen1WhirlyQueryForDoDeleteById,
    datascreen1SellmapInfoDoUpdate,
    datascreen2DoSave,
    datascreen2LoadDataBO,
    datascreen2SpeciesDoInsert,
    datascreen2SpeciesDoUpdate,
    datascreen2SpeciesDoDeleteById,
    datascreen2SpeciesQueryForPageList,
    datascreen2JobDoInsert,
    datascreen2JobDoUpdate,
    datascreen2JobDoDeleteById,
    datascreen2JobQueryForPageList,
    datascreen2SellDoInsert,
    datascreen2SellDoUpdate,
    datascreen2SellDoDeleteById,
    datascreen2SellQueryForPageList,
    datascreen2FeedDoInsert,
    datascreen2FeedDoUpdate,
    datascreen2FeedDoDeleteById,
    datascreen2FeedQueryForPageList,
    datascreen2WarnDoInsert,
    datascreen2WarnDoUpdate,
    datascreen2WarnDoDeleteById,
    datascreen2WarnQueryForPageList,
    datascreen2BaseFemaleStatisticsDoInsert,
    datascreen2BaseFemaleStatisticsDoUpdate,
    datascreen2BaseFemaleStatisticsDoDeleteById,
    datascreen2BaseFemaleStatisticsQueryForPageList,
    datascreen2FatteningSheepStatisticsDoInsert,
    datascreen2FatteningSheepStatisticsDoUpdate,
    datascreen2FatteningSheepStatisticsDoDeleteById,
    datascreen2FatteningSheepStatisticsQueryForPageList,
    datascreen2NewbornLambStatisticsDoInsert,
    datascreen2NewbornLambStatisticsDoUpdate,
    datascreen2NewbornLambStatisticsDoDeleteById,
    datascreen2NewbornLambStatisticsQueryForPageList,
    datascreen15DoSave,
    datascreen15LoadDataBO,
    datascreen3DoSave,
    datascreen3LoadDataBO,
    datascreen3TownshipAreaStatisticsDoInsert,
    datascreen3TownshipAreaStatisticsDoUpdate,
    datascreen3TownshipAreaStatisticsDoDeleteById,
    datascreen3TownshipAreaStatisticsQueryForPageList,
    datascreen3TownshipMapConfQueryForPageList,
    datascreen3TownshipMapConfDoInsert,
    datascreen3TownshipMapConfDoUpdate,
    datascreen3TownshipMapConfDoDeleteById,
    datascreen3EnterpriseAreaStatisticsDoInsert,
    datascreen3EnterpriseAreaStatisticsDoUpdate,
    datascreen3EnterpriseAreaStatisticsDoDeleteById,
    datascreen3EnterpriseAreaStatisticsQueryForPageList,
    datascreen5DoSave,
    datascreen5LoadDataBO,
    datascreen5AnnualTotalYieldDoInsert,
    datascreen5AnnualTotalYieldDoUpdate,
    datascreen5AnnualTotalYieldDoDeleteById,
    datascreen5AnnualTotalYieldQueryForPageList,
    datascreen5JobDoInsert,
    datascreen5JobDoUpdate,
    datascreen5JobDoDeleteById,
    datascreen5JobQueryForPageList,
    datascreen5MachineryDoInsert,
    datascreen5MachineryDoUpdate,
    datascreen5MachineryDoDeleteById,
    datascreen5MachineryForPageList,
    datascreen5MassifDoInsert,
    datascreen5MassifDoUpdate,
    datascreen5MassifDoDeleteById,
    datascreen5MassifQueryForPageList,
    datascreen5TaskDoInsert,
    datascreen5TaskDoUpdate,
    datascreen5TaskDoDeleteById,
    datascreen5TaskQueryForPageList,
    datascreen5WarnDoInsert,
    datascreen5WarnDoUpdate,
    datascreen5WarnDoDeleteById,
    datascreen5WarnQueryForPageList,
    datascreen6DoSave,
    datascreen6LoadDataBO,
    datascreen6AnnualTotalYieldDoInsert,
    datascreen6AnnualTotalYieldDoUpdate,
    datascreen6AnnualTotalYieldDoDeleteById,
    datascreen6AnnualTotalYieldQueryForPageList,
    datascreen6JobDoInsert,
    datascreen6JobDoUpdate,
    datascreen6JobDoDeleteById,
    datascreen6JobQueryForPageList,
    datascreen6MachineryDoInsert,
    datascreen6MachineryDoUpdate,
    datascreen6MachineryDoDeleteById,
    datascreen6MachineryForPageList,
    datascreen6MassifDoInsert,
    datascreen6MassifDoUpdate,
    datascreen6MassifDoDeleteById,
    datascreen6MassifQueryForPageList,
    datascreen6TaskDoInsert,
    datascreen6TaskDoUpdate,
    datascreen6TaskDoDeleteById,
    datascreen6TaskQueryForPageList,
    datascreen6WarnDoInsert,
    datascreen6WarnDoUpdate,
    datascreen6WarnDoDeleteById,
    datascreen6WarnQueryForPageList,
    datascreen4DoSave,
    datascreen4LoadDataBO,
    datascreen4AnnualTotalYieldDoInsert,
    datascreen4AnnualTotalYieldYieldDoUpdate,
    datascreen4AnnualTotalYieldYieldDoDeleteById,
    datascreen4AnnualTotalYieldQueryForPageList,
    datascreen4TaskDoInsert,
    datascreen4TaskDoUpdate,
    datascreen4TaskDoDeleteById,
    datascreen4TaskQueryForPageList,
    datascreen4WarnDoInsert,
    datascreen4WarnDoUpdate,
    datascreen4WarnDoDeleteById,
    datascreen4WarnQueryForPageList,
    datascreen11DoSave,
    datascreen11LoadDataBO,
    datascreen11AnnualTotalYieldDoInsert,
    datascreen11AnnualTotalYieldDoUpdate,
    datascreen11AnnualTotalYieldDoDeleteById,
    datascreen11AnnualTotalYieldQueryForPageList,
    datascreen11WarnDoInsert,
    datascreen11WarnDoUpdate,
    datascreen11WarnDoDeleteById,
    datascreen11WarnQueryForPageList,
    datascreen11PlantingLogDoInsert,
    datascreen11PlantingLogDoUpdate,
    datascreen11PlantingLogDoDeleteById,
    datascreen11PlantingLogQueryForPageList,
    datascreen11OrderDoInsert,
    datascreen11OrderDoUpdate,
    datascreen11OrderDoDeleteById,
    datascreen11OrderQueryForPageList,
    datascreen12DoSave,
    datascreen12LoadDataBO,
    datascreen12TownshipMapConfQueryForPageList,
    datascreen12TownshipMapConfDoInsert,
    datascreen12TownshipMapConfDoUpdate,
    datascreen12TownshipMapConfDoDeleteById,
    datascreen12ShareMachineryOrderStatisticsDoInsert,
    datascreen12ShareMachineryOrderStatisticsDoUpdate,
    datascreen12ShareMachineryOrderStatisticsDoDeleteById,
    datascreen12ShareMachineryOrderStatisticsQueryForPageList,
    datascreen12TownshipAgriculturalMachineryDoInsert,
    datascreen12TownshipAgriculturalMachineryDoUpdate,
    datascreen12TownshipAgriculturalMachineryDoDeleteById,
    datascreen12TownshipAgriculturalMachineryQueryForPageList,
    datascreen12AgriculturalStatisticsQueryForPageList,
    datascreen12AgriculturalStatisticsDoInsert,
    datascreen12AgriculturalStatisticsDoUpdate,
    datascreen12AgriculturalStatisticsDoDeleteById,
    datascreen12AreaOfOperationTypeStatisticsDoInsert,
    datascreen12AreaOfOperationTypeStatisticsDoUpdate,
    datascreen12AreaOfOperationTypeStatisticsDoDeleteById,
    datascreen12AreaOfOperationTypeStatisticsQueryForPageList,
    datascreen12NorthTownshipOperationAreaStatisticsDoInsert,
    datascreen12NorthTownshipOperationAreaStatisticsDoUpdate,
    datascreen12NorthTownshipOperationAreaStatisticsDoDeleteById,
    datascreen12NorthTownshipOperationAreaStatisticsQueryForPageList,
    datascreen12SouthTownshipOperationAreaStatisticsDoInsert,
    datascreen12SouthTownshipOperationAreaStatisticsDoUpdate,
    datascreen12SouthTownshipOperationAreaStatisticsDoDeleteById,
    datascreen12SouthTownshipOperationAreaStatisticsQueryForPageList,
    datascreen13VarietiesQueryForPageList,
    datascreen13VarietiesDoInsert,
    datascreen13VarietiesDoUpdate,
    datascreen13VarietiesDoDeleteById,

    datascreen10DoSave,
    datascreen10LoadDataBO,
    datascreen10ConsumptionStatisticsDoInsert,
    datascreen10ConsumptionStatisticsDoUpdate,
    datascreen10ConsumptionStatisticsDoDeleteById,
    datascreen10ConsumptionStatisticsQueryForPageList,
    datascreen10StorageEnterpriseDoInsert,
    datascreen10StorageEnterpriseDoUpdate,
    datascreen10StorageEnterpriseDoDeleteById,
    datascreen10StorageEnterpriseQueryForPageList,

    datascreen3EnterpriseMapInfoDoBatchInsert,
    datascreen3EnterpriseMapInfoDoUpdate,
    datascreen3EnterpriseMapInfoDoDeleteById,
    datascreen3EnterpriseMapInfoQueryForPageList,
    datascreen15AddCompany,
    datascreen15queryForList,
    datascreen15EditCompany,
    datascreen15DelCompany,
    datascreen16DoInsert,
    datascreen16QueryByParams,
    datascreen16VarietiesQueryForPageList,
    datascreen16VarietiesDoInsert,
    datascreen16VarietiesDoUpdate,
    datascreen16VarietiesDoDeleteById,
    datascreen16JobQueryForPageList,
    datascreen16JobDoInsert,
    datascreen16JobDoUpdate,
    datascreen16JobDoDeleteById,
    datascreen16PriceQueryForPageList,
    datascreen16PriceDoInsert,
    datascreen16PriceDoUpdate,
    datascreen16PriceDoDeleteById,
    datascreen16TownQueryForPageList,
    datascreen16TownDoInsert,
    datascreen16TownDoUpdate,
    datascreen16TownDoDeleteById,
    datascreen16StatisticsQueryForPageList,
    datascreen16StatisticsMothQueryForPageList,
    datascreen16StatisticsDoInsert,
    datascreen16StatisticsMothDoInsert,
    datascreen16StatisticsMothDoUpdate,
    datascreen16StatisticsMothDoDeleteById,
    datascreen16StatisticsDoUpdate,
    datascreen16StatisticsDoDeleteById,
    datascreen17CooperativeQueryForPageList,
    datascreen17FacilitiesDoInsert,
    datascreen17FacilitiesDoUpdate,
    datascreen17CooperativedoDoBatchDelete,

    datascreen17DoUpdate,
    datascreen17QueryUniqueObject,

    datascreen17WorkMapAdd,
    datascreen17WorkMapEdit,
    datascreen17WorkMapDeleteById,
    datascreen17WorkMapQueryForPageList,
    
    datascreen17TrademarkQueryForPageList,
    datascreen17TrademarkDoInsert,
    datascreen17TrademarkDoUpdate,
    datascreen17TrademarkDeleteById,

    datascreen17AllAreaSowingPriceQueryForPageList,
    datascreen17AllAreaSowingPriceDoInsert,
    datascreen17AllAreaSowingPriceDoUpdate,
    datascreen17AllAreaSowingPriceDoBatchDelete,

    datascreen17AllAreaSowingStatisticsQueryForPageList,
    datascreen17AllAreaSowingStatisticsDoInsert,
    datascreen17AllAreaSowingStatisticsDoUpdate,
    datascreen17AllAreaSowingStatisticsDoBatchDelete,

    datascreen17TownshipsVegetableAreaStatisticsQueryForPageList,
    datascreen17TownshipsVegetableAreaStatisticsDoInsert,
    datascreen17TownshipsVegetableAreaStatisticsDoUpdate,
    datascreen17TownshipsVegetableAreaStatisticsDoBatchDelete,



    datascreen18AllAreaSowingPriceQueryForPageList,
    datascreen18AllAreaSowingPriceDoInsert,
    datascreen18AllAreaSowingPriceDoUpdate,
    datascreen18AllAreaSowingPriceDoDeleteById,

    datascreen18AllAreaSowingStatisticsQueryForPageList,
    datascreen18AllAreaSowingStatisticsDoInsert,
    datascreen18AllAreaSowingStatisticsDoUpdate,
    datascreen18AllAreaSowingStatisticsDoBatchDelete,
    datascreen18AllAreaSowingStatisticsDoDeleteById,

    datascreen18TownshipsVegetableAreaStatisticsQueryForPageList,
    datascreen18TownshipsVegetableAreaStatisticsDoInsert,
    datascreen18TownshipsVegetableAreaStatisticsDoUpdate,
    datascreen18TownshipsVegetableAreaStatisticsDoBatchDelete,

    datascreen18QueryById,
    datascreen18DoInsert,
    datascreen18WorkMapQueryForPageList,
    datascreen18WorkMapDoInsert,
    datascreen18WorkMapDoUpdate,
    datascreen18WorkMapDoDeleteById,
    datascreen18TownshipsSowingStatisticsQueryForPageList,
    datascreen18TownshipsSowingStatisticsDoInsert,
    datascreen18TownshipsSowingStatisticsDoUpdate,
    datascreen18TownshipsSowingStatisticsDoDeleteById,

    doInsertDatascreen3Camera,
    datascreen3CameraQueryForPageList,
    datascreen3CameraQueryDoDeleteById,
    doInsertDatascreen3Sensor,
    datascreen3SensorQueryForPageList,
    doDeleteByIdQueryForPageList,
    datascreenDeviceEnterpriseRelationDoInsert,
    datascreenDeviceEnterpriseRelationueryForPageList,
    datascreenDeviceEnterpriseRelatiDoDeleteById,

    datascreen15TownshipMapConfQueryForPageList,
    datascreen15TownshipMapConfDoInsert,
    datascreen15TownshipMapConfDoUpdate,
    datascreen15TownshipMapConfDoDeleteById,

    datascreen9TownshipMapConfQueryForPageList,
    datascreen9TownshipMapConfDoInsert,
    datascreen9TownshipMapConfDoUpdate,
    datascreen9TownshipMapConfDoDeleteById,

    datascreen10CropsDoInsert,
    datascreen10CropsDoUpdate,
    datascreen10CropsQeryForPageList,
    datascreen10CropsDoDeleteById, 

    datascreen10StoretypeDoInsert,
    datascreen10StoretypeDoUpdate,
    datascreen10StoretypeDoDeleteById,
    datascreen10StoretypeQueryForPageList,

    datascreen10TownshipMapConfQueryForPageList,
    datascreen10TownshipMapConfDoInsert,
    datascreen10TownshipMapConfDoUpdate,
    datascreen10TownshipMapConfDoDeleteById,

    datascreen10TownshipColdchainInfoDoUpdate,
    datascreen10TownshipColdchainInfoDoDeleteById,
    datascreen10TownshipColdchainInfoQueryForPageList,
    datascreen10TownshipColdchainInfoDoInsert,

    gpsDoInsert,
    gpsDoUpdate,
    gpsDoDeleteById,
    gpsQueryForPageList
}
