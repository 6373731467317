import user from "./user.js"
import screen from "./screen.js"
import setScreen from "./setScreen.js"
let indexApi = {};
let planting = {
  user,
  screen,
  setScreen
}

for(let item in planting){
  console.log(planting)
  for(let obj in planting[item]){
    
    indexApi[obj] = planting[item][obj];
  }
}


export default {
  indexApi
}

// require("./iot.js")
// require("./material.js")
// require("./temporary.js")
// require("./user.js")