import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import VueQuillEditor from 'vue-quill-editor'
import 'element-ui/lib/theme-chalk/index.css'
import 'animate.css';
import indexApi from './api/index'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import './assets/css/reset.scss'
import FWZX from './utils/util'
import BaiduMap from 'vue-baidu-map'
import scroll from 'vue-seamless-scroll'
import {
  highPrecisionAdd, // 加
  highPrecisionReduce, // 减
  highPrecisionMul, // 乘
  highPrecisionDiv // 除
} from './utils/tools'

Vue.use(scroll)
Vue.use( BaiduMap, { ak : 'r6LBT8SiG2vN6qTt5By4jCQ55gjGCCZA' } )
Vue.prototype.FWZX = FWZX.FWZX
Vue.prototype.highPrecisionAdd = highPrecisionAdd // 加
Vue.prototype.highPrecisionReduce = highPrecisionReduce // 减
Vue.prototype.highPrecisionMul = highPrecisionMul // 乘
Vue.prototype.highPrecisionDiv = highPrecisionDiv // 除
Vue.use(VueQuillEditor)
Vue.use(ElementUI)
Vue.prototype.qa = indexApi.indexApi
Vue.config.productionTip = false
ElementUI.Dialog.props.closeOnClickModal.default = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
